import { Button } from '@mui/material';
import { ProviderContext as SnackbarContext } from 'notistack';

export function enqueueErrorSnackbar(snackbarContext: SnackbarContext, message: string, key: string) {
  snackbarContext.enqueueSnackbar(message, {
    key: key,
    preventDuplicate: true,
    anchorOrigin: { horizontal: 'center', vertical: 'top' },
    variant: 'error',
    persist: true,
    action: (key) => (
      <Button
        variant={'contained'}
        color={'primary'}
        size={'small'}
        onClick={() => {
          snackbarContext.closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    ),
  });
}
