import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authNamespace } from '@/features/Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '@/features/Authentication/Auth0Types';
import SmartMooringContext from '@/features/Common/SmartMooringContext';

function useOrganisation(context = SmartMooringContext) {
  const { user: auth0User } = useAuth0();
  const auth0UserProfile: CustomClaims | undefined = auth0User?.[authNamespace];
  const auth0OrganisationId = auth0UserProfile?.organization_id ?? '';

  const smartMooringContext = useContext(context);

  return {
    organisationName: auth0UserProfile?.organization_name,
    organisationId: auth0OrganisationId,

    ...smartMooringContext,
  };
}

export default useOrganisation;
