import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useProfileServiceGetOrganisation, useProfileServiceGetUser } from '@/api/ui/queries';
import PermissionDenied from '@/pages/PermissionDenied/PermissionDenied';
import { authNamespace } from '../Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '../Authentication/Auth0Types';
import SmartMooringContext, { SmartMooringContextInterface } from './SmartMooringContext';

export interface SmartMooringProviderOptions {
  children?: React.ReactNode;
  context?: React.Context<SmartMooringContextInterface>;
}

export const localStorageOrganisationIdKey = 'selectedOrganisationId';
const getLocalStorageOrganisationId = () => localStorage.getItem(localStorageOrganisationIdKey);

const profileQueryOptions = (enabled: boolean) => ({
  enabled,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
});

const LoadingOrganisation = () => (
  <Box marginLeft={-1} display="flex" justifyContent="center" alignItems="center" height="90vh" width="100vw">
    <img src="/images/logo.png" width={200} alt="Smart mooring solution" />
    <Box paddingLeft={4} marginTop={-2} minWidth={240}>
      <Box className={'spinner'} height={48} width={48} />
      <span className={'loading-message'}>Retrieving organisation data...</span>
    </Box>
    <img src="/images/twinn-logo.png" width={100} alt="Twinn.io" />
  </Box>
);

const SmartMooringProvider = (opts: SmartMooringProviderOptions): JSX.Element => {
  const { children, context = SmartMooringContext } = opts;
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0();
  const auth0UserProfile: CustomClaims | undefined = auth0User?.[authNamespace];
  const [selectedOrganisationId, setSelectedOrganisationId] = useState(getLocalStorageOrganisationId());
  const [pageTitle, setPageTitle] = useState('');
  const [pageTitleIcon, setPageTitleIcon] = useState<ReactElement | undefined>(undefined);
  const isQueryEnabled = !!selectedOrganisationId && !!auth0UserProfile;

  const { data: selectedOrganisation, error: organisationError } = useProfileServiceGetOrganisation(
    { xSelectedOrganisationId: selectedOrganisationId },
    [selectedOrganisationId],
    { ...profileQueryOptions(isQueryEnabled), retry: 2 }
  );
  const { data: userProfile, error: userProfileError } = useProfileServiceGetUser([selectedOrganisationId], profileQueryOptions(isQueryEnabled));

  useEffect(() => {
    if (!auth0UserProfile) return;
    if (selectedOrganisationId) return;

    // If selectedOrganisationId is not set, set it to the organisation_id from the user profile.
    const auth0OrganisationId = auth0UserProfile?.organization_id;
    if (auth0OrganisationId) {
      setSelectedOrganisationId(() => auth0OrganisationId);
      localStorage.setItem(localStorageOrganisationIdKey, auth0OrganisationId);
    }
  }, [auth0UserProfile, selectedOrganisationId]);

  useEffect(() => {
    if (!userProfile) return;
    if (!auth0UserProfile) return;
    if (!selectedOrganisationId) return;
    if (!userProfile?.allowedOrganisations?.some((x) => x.organisationId == selectedOrganisationId)) {
      localStorage.removeItem(localStorageOrganisationIdKey);
      return;
    }

    localStorage.setItem(localStorageOrganisationIdKey, selectedOrganisationId);
  }, [auth0UserProfile, selectedOrganisationId, userProfile]);

  const switchOrganisation = useCallback(
    (orgId: string) => {
      setSelectedOrganisationId(orgId);
      navigate('/');
    },
    [setSelectedOrganisationId, navigate]
  );

  if (userProfileError) {
    return <PermissionDenied />;
  }

  if (organisationError) {
    const allowedOrganisationId = userProfile?.allowedOrganisations?.at(0)?.organisationId;
    if (!allowedOrganisationId) return <PermissionDenied />;

    // If the selected organisation is not allowed, set the first allowed organisation as the selected organisation.
    setSelectedOrganisationId(allowedOrganisationId);
  }

  if (isQueryEnabled && (!selectedOrganisation || !userProfile)) {
    return <LoadingOrganisation />;
  }

  return (
    <context.Provider
      value={{
        switchOrganisation,
        selectedOrganisationId,
        selectedOrganisation: selectedOrganisation,
        allowedOrganisations: userProfile?.allowedOrganisations ?? [],
        pageTitle,
        setPageTitle,
        pageTitleIcon,
        setPageTitleIcon,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default SmartMooringProvider;
