import { Box, Grid, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDmaLibraryServiceGetLibraryDmaCases } from '@/api/ui/queries';
import { DmaCaseDefinition, VesselType } from '@/api/ui/requests';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import usePageTitle from '@/hooks/usePageTitle';
import { StyledTableCell, StyledTableRow } from '@/pages/DmaCaseLibrary/Tables';
import { classifictionCriteria } from '@/pages/DmaCaseLibrary/VesselClassUtils';

function DmaCaseDefinitionRow({ dmaCaseDefinition, showSpecific }: { dmaCaseDefinition: DmaCaseDefinition; showSpecific: boolean }) {
  return (
    <StyledTableRow key={`${dmaCaseDefinition.dmaCaseId}}`}>
      <StyledTableCell>{dmaCaseDefinition.vesselClassDefinition.name}</StyledTableCell>
      <StyledTableCell align={'right'}>{classifictionCriteria(dmaCaseDefinition.vesselClassDefinition)}</StyledTableCell>
      <StyledTableCell>{dmaCaseDefinition.loadingConditions.join(', ')}</StyledTableCell>
      {showSpecific && <StyledTableCell>{dmaCaseDefinition.classifier !== 'default' ? dmaCaseDefinition.classifier : ''}</StyledTableCell>}
    </StyledTableRow>
  );
}

export function DmaCaseVesselType({
  vesselType,
  dmaCaseDefinitions,
  showSpecific,
}: {
  dmaCaseDefinitions: DmaCaseDefinition[];
  vesselType: VesselType;
  showSpecific: boolean;
}) {
  const sortedDefinitions = dmaCaseDefinitions.sort((a, b) => {
    if (a.vesselClassDefinition.minimumValue == null) return -1;

    if (a.vesselClassDefinition.maximumValue == null) return -1;

    return (
      a.vesselClassDefinition.minimumValue < b.vesselClassDefinition.minimumValue &&
      a.vesselClassDefinition.maximumValue < b.vesselClassDefinition.maximumValue &&
      a.vesselClassDefinition.name.localeCompare(b.vesselClassDefinition.name)
    );
  });
  return (
    <Grid item md={12} lg={6} xl={4}>
      <Typography variant="h2">{vesselType}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell width={100}>Vessel class</StyledTableCell>
            <StyledTableCell align={'right'} width={50}>
              Criteria
            </StyledTableCell>
            <StyledTableCell width={50}>Loading conditions</StyledTableCell>
            {showSpecific && <StyledTableCell width={50}>Specific</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedDefinitions.map((dmaCaseDefinition) => (
            <DmaCaseDefinitionRow
              key={`${vesselType}-${dmaCaseDefinition.dmaCaseId}-row`}
              dmaCaseDefinition={dmaCaseDefinition}
              showSpecific={showSpecific}
            />
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

function DmaCaseLibraryOverview() {
  usePageTitle('Dynamic Mooring Analysis Case Library');

  const dmaCases = useDmaLibraryServiceGetLibraryDmaCases();
  if (dmaCases.isLoading)
    return (
      <Box>
        <Typography>Loading dma cases...</Typography>
      </Box>
    );
  if (dmaCases.isError) return <ApiErrorAlert error={dmaCases.error} />;

  return (
    <Box p={5}>
      <Typography variant="h1">Dynamic Mooring Analysis Case Library</Typography>
      <Typography variant="body1">
        Smart Mooring has a library of Dynamic Mooring Analysis (DMA) cases that can be used to determine the safety of vessels moored in your
        harbour. This library consists of DMA cases that do not take into account specific situations for either your berth of a specific vessel,
        these are the so called <i>Generic</i> DMA cases. Below you find a list of all cases currently available in our library.
      </Typography>
      <Grid container spacing={6} pt={5}>
        {dmaCases.data.map((vesselTypeDmaCases) => (
          <DmaCaseVesselType
            key={vesselTypeDmaCases.vesselType}
            vesselType={vesselTypeDmaCases.vesselType}
            dmaCaseDefinitions={vesselTypeDmaCases.dmaCaseDefinitions}
            showSpecific={false}
          />
        ))}
      </Grid>
    </Box>
  );
}

export default DmaCaseLibraryOverview;
