import { ApplicationSettings } from '@/types';

const title = 'Smart Mooring';

const email = 'smart.mooring.support@royalhaskoningdhv.com';

const messages = {
  app: {
    crash: {
      title: 'Oooops... Sorry, I guess, something went wrong.',
      options: {
        email: `contact with author by this email - ${email}`,
        reset: 'Refresh',
        home: 'Back to homepage',
      },
    },
  },
  404: 'This page seems to be missing.',
};

const parseFeatureFlags = () => {
  try {
    return JSON.parse(import.meta.env.VITE_FEATURE_FLAGS);
  } catch (e) {
    console.error('Error parsing feature flags', e);
    return [];
  }
};

const appSettings: ApplicationSettings = {
  authentication: {
    clientId: import.meta.env.VITE_SMART_SOCIETY_CLIENT_ID ? import.meta.env.VITE_SMART_SOCIETY_CLIENT_ID : '',
    audience: import.meta.env.VITE_SMART_SOCIETY_AUDIENCE,
    domain: import.meta.env.VITE_SMART_SOCIETY_DOMAIN ? import.meta.env.VITE_SMART_SOCIETY_DOMAIN : 'login.smart-society.io',
    issuer: import.meta.env.VITE_SMART_SOCIETY_ISSUER ? import.meta.env.VITE_SMART_SOCIETY_ISSUER : 'https://login.smart-society.io/',
    scopes: 'read:current_user dma-cases:upload dma-cases:view system-admin manage-berths manage-organisations historic-data-regeneration',
  },
  leaflet: {
    accessToken: import.meta.env.VITE_LEAFLET_ACCESS_TOKEN,
    mapUrl: import.meta.env.VITE_LEAFLET_MAP_URL,
  },
  analytics: { token: import.meta.env.VITE_ANALYTICS_TOKEN },
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    managementEndpoint: `${import.meta.env.VITE_API_BASE_URL}/management`,
    uiEndpoint: `${import.meta.env.VITE_API_BASE_URL}/ui`,
  },
  license: {
    productName: 'Smart Mooring',
  },
  featureFlags: import.meta.env.VITE_FEATURE_FLAGS ? parseFeatureFlags() : [],
  environment: import.meta.env.VITE_ENV?.toLowerCase() ?? 'dev',
  deployment: import.meta.env.VITE_DEPLOYMENT ?? 'prod',
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV ?? 'local',
  },
};

export { title, email, messages, appSettings };
