import { Box, Stack, Typography } from '@mui/material';
import SmartMooringLogo from '@/assets/images/smartmooring-logo.png';
import TwinnLogo from '@/assets/images/twinn-logo.png';
import usePageTitle from '@/hooks/usePageTitle';
import versionInfo from '@/versionInfo.json';

function About() {
  usePageTitle('About Smart Mooring Solution');

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
        <Typography variant={'h1'} role={'heading'}>
          Smart Mooring
        </Typography>
        <Box width={'50%'}>
          <Typography variant={'h2'} sx={{ paddingBottom: 2 }}>
            We create safer, smarter and more efficient ports
          </Typography>
          <Typography variant={'body1'} sx={{ paddingBottom: 2 }}>
            Increase safety and gain operational uptime with our Smart Mooring software for port authorities, terminal operators and shipping lines
          </Typography>
          <Typography variant={'body1'} sx={{ paddingBottom: 2 }}>
            With a predictive decision support solution that uses real-time data and metocean forecasts to enhance vessel mooring and related
            operations. When it comes to mooring, each individual port, terminal, and individual berth has different safety and operational needs.
          </Typography>
          <Typography variant={'body1'} sx={{ paddingBottom: 2 }}>
            With Smart Mooring, you can create the perfect overview, with decision-making for every berth on the map supported by the right levels of
            intelligence.
          </Typography>
          <Typography variant={'h2'} sx={{ paddingBottom: 2 }}>
            A digital twin of the ship-shore interface
          </Typography>
          <Typography variant={'body1'} sx={{ paddingBottom: 2 }}>
            A Digital twin of the ship-shore interface Driven by relevant data, a digital twin is a virtual mirror of a real-world asset, process or
            even an entire system, in this case the ship-shore interface. This mirror provides insight into how the interaction between ship and shore
            under a variety of simulated conditions, helping you to improve decision-making and optimize business processes.
          </Typography>
          <Typography variant={'body1'} title={'version'} sx={{ paddingBottom: 2 }}>
            Version: {versionInfo.version}-{versionInfo.gitCommitHash}
          </Typography>
        </Box>
        <Box alignContent={'center'}>
          <img src={SmartMooringLogo} width={450} alt={'Smart Mooring logo'} />
          <img src={TwinnLogo} width={250} alt={'Twinn logo'} />
        </Box>
      </Stack>
    </Box>
  );
}

export default About;
