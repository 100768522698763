import { useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, IconButton, TableCell } from '@mui/material';
import { Box } from '@mui/system';
import { Header, Table, flexRender } from '@tanstack/react-table';
import GridOverviewFilter from './GridOverviewFilter';
import { GridOverviewColumnMetadata } from './types';

interface GridOverviewTableHeaderCellProps<TRowModel> {
  table: Table<TRowModel>;
  header: Header<TRowModel, unknown>;
  metadata: GridOverviewColumnMetadata;
  width: number;
}

function GridOverviewTableHeaderCell<TRowModel>({ table, header, metadata, width }: GridOverviewTableHeaderCellProps<TRowModel>) {
  const [showFilterBar, setShowFilterBar] = useState(false);
  return (
    <>
      <TableCell sx={{ paddingY: 1, paddingRight: 0, width: width }} colSpan={header.colSpan} align={metadata.align}>
        {header.isPlaceholder ? null : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{ width: '100%', ...(header.column.getCanSort() ? { cursor: 'pointer' } : {}) }}
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
                {
                  {
                    asc: (
                      <IconButton size="small">
                        <ArrowUpwardIcon sx={{ fontSize: 'inherit' }} />
                      </IconButton>
                    ),
                    desc: (
                      <IconButton size="small">
                        <ArrowDownwardIcon sx={{ fontSize: 'inherit' }} />
                      </IconButton>
                    ),
                  }[(header.column.getIsSorted() as string) ?? null]
                }
              </Box>
              {metadata.showFilterIcon && (
                <Box sx={{ flexShrink: 1 }}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (showFilterBar) {
                        header.column.setFilterValue('');
                      }
                      setShowFilterBar(!showFilterBar);
                    }}
                  >
                    {showFilterBar ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                </Box>
              )}
              <Divider orientation="vertical" variant="middle" flexItem />
            </Box>
            {showFilterBar && header.column.getCanFilter() && <GridOverviewFilter column={header.column} table={table} />}
          </>
        )}
      </TableCell>
    </>
  );
}

export default GridOverviewTableHeaderCell;
