import { Box, Grid, Table, TableBody, TableHead, Typography } from '@mui/material';
import { useDmaLibraryServiceGetVesselClasses } from '@/api/ui/queries';
import { VesselClassDefinition, VesselType } from '@/api/ui/requests';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import usePageTitle from '@/hooks/usePageTitle';
import { StyledTableCell, StyledTableRow } from '@/pages/DmaCaseLibrary/Tables';
import { classifictionCriteria } from './VesselClassUtils';

function VesselClassDefinitionRow({ vesselClassDefinition }: { vesselClassDefinition: VesselClassDefinition }) {
  return (
    <StyledTableRow key={`${vesselClassDefinition.name}}`}>
      <StyledTableCell>{vesselClassDefinition.name}</StyledTableCell>
      <StyledTableCell align={'right'}>{classifictionCriteria(vesselClassDefinition)}</StyledTableCell>
      <StyledTableCell>{vesselClassDefinition.label}</StyledTableCell>
    </StyledTableRow>
  );
}

function VesselTypeClassesDefinitionsTable({
  vesselType,
  vesselClassDefinitions,
}: {
  vesselClassDefinitions: VesselClassDefinition[];
  vesselType: VesselType;
}) {
  return (
    <Grid item sm={12} lg={6} xl={4}>
      <Typography variant="h2" p={2}>
        {vesselType}
      </Typography>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell width={100}>Name</StyledTableCell>
            <StyledTableCell align={'right'} width={50}>
              Criteria
            </StyledTableCell>
            <StyledTableCell width={50}>Label</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {vesselClassDefinitions.map((vesselClassDefinition) => (
            <VesselClassDefinitionRow key={`${vesselType}-${vesselClassDefinition.name}-row`} vesselClassDefinition={vesselClassDefinition} />
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

function VesselClassOverview() {
  usePageTitle('Vessel Classes');

  const vesselClasses = useDmaLibraryServiceGetVesselClasses();
  if (vesselClasses.isLoading)
    return (
      <Box>
        <Typography>Loading vessel classes...</Typography>
      </Box>
    );
  if (vesselClasses.isError) return <ApiErrorAlert error={vesselClasses.error} />;

  return (
    <Box p={5}>
      <Typography variant="h1">Vessel Classes</Typography>
      <Typography variant="body1">
        This page shows all vessel class definitions that Smart Mooring uses to identify which DMA case can be used for a vessel.
      </Typography>
      <Grid container spacing={6} pt={5}>
        {vesselClasses.data.map((vesselClass) => (
          <VesselTypeClassesDefinitionsTable
            key={vesselClass.vesselType}
            vesselType={vesselClass.vesselType}
            vesselClassDefinitions={vesselClass.vesselClassDefinitions}
          />
        ))}
      </Grid>
    </Box>
  );
}

export default VesselClassOverview;
