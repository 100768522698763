import HomeIcon from '@mui/icons-material/Home';
import RestartIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import ErrorImage from '@/assets/images/error-404.png';
import { messages } from '@/services/config';

function resetApp() {
  return window.location.reload();
}

function home() {
  return (window.location.href = '/');
}

function ErrorFallbackPage(props: FallbackProps) {
  if (props.error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.reload();
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Grid container>
        <Grid item sm={12} alignItems={'center'} alignContent={'center'}>
          <Typography variant="h5" component="h3">
            {messages.app.crash.title}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} sx={{ objectFit: 'contain', maxWidth: { sm: '250px', md: '400px', lg: '600px' } }}>
          <img src={ErrorImage} alt={'Error'} width={'100%'} />
        </Grid>
        <Grid item sm={12} md={5}>
          <Paper sx={{ p: 5 }}>
            <Typography variant="body2" component="div" color={'error'}>
              {props.error.message}
            </Typography>
            <Button startIcon={<RestartIcon />} sx={{ mt: 3 }} variant="outlined" onClick={resetApp}>
              {messages.app.crash.options.reset}
            </Button>
            <Button startIcon={<HomeIcon />} sx={{ mt: 3 }} variant="outlined" onClick={home}>
              {messages.app.crash.options.home}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ErrorFallbackPage;
