import { styled } from '@mui/material';
import { ReactComponent as MarkerSvg } from '@/assets/svg/marker.svg';

export const StyledBerthMarkerDefaultSvg = styled(MarkerSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.primary.main },
  height: '27px',
}));

export const BerthMarkerBlackSvg = styled(MarkerSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.text.primary },
  height: '27px',
}));

export const BelowLowerLimitBerthIcon = styled(MarkerSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.below_lower_limit.main },
  height: '27px',
}));

export const DisabledBerthIcon = styled(MarkerSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.disabled.main },
  height: '27px',
}));

export const AboveUpperLimitBerthIcon = styled(BerthMarkerBlackSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.above_upper_limit.main },
  height: '27px',
}));

export const AboveUpperCustomerLimitBerthIcon = styled(BerthMarkerBlackSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.above_upper_customer_limit.main },
  height: '27px',
}));

export const AboveLowerLimitBerthIcon = styled(MarkerSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.above_lower_limit.main },
  height: '27px',
}));
