import { MouseEvent } from 'react';
import { EmailRounded, Sync } from '@mui/icons-material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Button, Grid, Tooltip } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { FilterFn, Row, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { usePeopleManagementServiceListPeople, usePeopleManagementServiceSavePreferences } from '@/api/management/queries';
import { PeopleManagementService, UserPreferences } from '@/api/management/requests';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import GridOverview from '@/components/management/GridOverview/GridOverview';
import GridOverviewTooltipIcon from '@/components/management/GridOverview/GridOverviewTooltipIcon';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from '@/components/management/GridOverview/types';
import usePageTitle from '@/hooks/usePageTitle';

const columnHelper = createColumnHelper<PersonSummary>();

const getLastLoginTimestampValue = (value: string) => {
  if (value === undefined || value === null || value === '') return '';
  return moment(value).format('DD MMM YYYY HH:mm:ss') ?? '';
};

const getColumns = (navigate: NavigateFunction, toggleDailyReport: (person: PersonSummary) => void) => [
  columnHelper.accessor('organisationName', {
    header: 'Organisation',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('emailAddress', {
    header: 'Email address',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('allowedOrganisations', {
    header: 'Allowed Organisations',
    filterFn: 'includesString',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('lastLogonTimestamp', {
    header: 'Last login',
    cell: (info) => getLastLoginTimestampValue(info.getValue() ?? ''),
    filterFn: ((row, columnId, value: string) => {
      const lastLogonTimestamp = getLastLoginTimestampValue(row.getValue(columnId) ?? '');
      return !!lastLogonTimestamp.toLocaleLowerCase().match(value.toLocaleLowerCase());
    }) as FilterFn<PersonSummary>,
    sortingFn: (a: Row<PersonSummary>, b: Row<PersonSummary>) => {
      return a.original.lastLogonTimestamp == null ? 1 : b.original.lastLogonTimestamp.localeCompare(a.original.lastLogonTimestamp);
    },
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('userPreferences', {
    header: 'Daily report',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 0.5 } as GridOverviewColumnMetadata,
    cell: (info) =>
      info.getValue()?.sendDailySafetyPredictionEmail ? (
        <Tooltip title={'Receive a daily safety prediction report by email'}>
          <EmailRounded color={'success'} />
        </Tooltip>
      ) : (
        <Tooltip title={'Daily safety prediction report disabled'}>
          <EmailRounded color={'error'} />
        </Tooltip>
      ),
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Actions',
    enableSorting: false,
    meta: { align: 'center', flex: 1, showFilterIcon: false } as GridOverviewColumnMetadata,
    cell: (props) => {
      const personId = props.row.original.personId;
      const dailyReport = props.row.original.userPreferences?.sendDailySafetyPredictionEmail;

      const onViewAuditTrail = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // don't select this row after clicking

        navigate(`/admin/audit-trail?type=Person&identifier=${personId}`);
      };

      const onToggleDailyReport = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // don't select this row after clicking

        toggleDailyReport(props.row.original);
      };

      return (
        <>
          <GridOverviewTooltipIcon title="Audit Trail" onClick={onViewAuditTrail} icon={<ChecklistIcon />} />
          <GridOverviewTooltipIcon
            title="Toggle daily report"
            onClick={onToggleDailyReport}
            icon={<EmailRounded color={dailyReport ? 'error' : 'success'} />}
          />
        </>
      );
    },
  }),
];

type PersonSummary = {
  personId: string;
  organisationName: string;
  name: string;
  emailAddress: string;
  allowedOrganisations: string[];
  lastLogonTimestamp: string;
  userPreferences: UserPreferences;
};

function PeopleOverview() {
  const navigate = useNavigate();
  const { data, error, isError } = usePeopleManagementServiceListPeople();
  const savePreferences = usePeopleManagementServiceSavePreferences();
  const pageTitle = 'People';

  usePageTitle(pageTitle);

  if (isError) {
    return <ApiErrorAlert error={error} />;
  }

  function toggleSendDailyReport(personId: string) {
    const person = data?.find((p) => p.personId === personId);
    if (person) {
      const userPreferences = {
        defaultOrganisationId: person.organisationId,
        sendDailySafetyPredictionEmail: !(person.userPreferences ? person.userPreferences.sendDailySafetyPredictionEmail : false),
      };
      savePreferences.mutate.call(null, {
        personId: personId,
        requestBody: {
          personId: personId,
          userPreferences: userPreferences,
        },
      });
      person.userPreferences = userPreferences;
    }
  }

  function handleSynchronisePeople() {
    PeopleManagementService.refreshPeople();
  }

  if (data) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={() => handleSynchronisePeople()} endIcon={<Sync />}>
            Refresh people from Smart Society
          </Button>
        </Grid>
        <Grid item xs={12}>
          <GridOverview
            columns={getColumns(navigate, (person: PersonSummary) => toggleSendDailyReport(person.personId))}
            title={pageTitle}
            data={
              data
                ?.sort((a, b) => (a.lastLogonTimestamp == null ? 1 : b.lastLogonTimestamp.localeCompare(a.lastLogonTimestamp)))
                .map((person) => {
                  return {
                    personId: person.personId,
                    organisationName: person.organisationName,
                    name: person.name,
                    emailAddress: person.emailAddress,
                    allowedOrganisations: person.allowedOrganisations.map((org) => org.organisationName),
                    lastLogonTimestamp: person.lastLogonTimestamp,
                    userPreferences: person.userPreferences,
                  };
                }) ?? []
            }
            getRowId={(row) => row.personId}
          />
        </Grid>
      </Grid>
    );
  }

  return <LoadingIndicator message={'Retrieving people'} />;
}

export default PeopleOverview;
