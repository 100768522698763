import { TableCell, TableRow } from '@mui/material';
import { Table } from '@tanstack/react-table';
import GridOverviewTableBodyCell from './GridOverviewTableBodyCell';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from './types';

interface GridOverviewTableBodyProps<TRowModel> {
  table: Table<TRowModel>;
}

function GridOverviewTableBody<TRowModel>({ table }: GridOverviewTableBodyProps<TRowModel>) {
  if (table.getRowModel().rows.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={table.getAllLeafColumns().length} align="center">
          <span>No rows.</span>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {table.getRowModel().rows.map((row) => {
        return (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const metadata = (cell.column.columnDef.meta as GridOverviewColumnMetadata) ?? DefaultGridOverviewColumnMetadata;
              return <GridOverviewTableBodyCell key={cell.id} cell={cell} metadata={metadata} />;
            })}
          </TableRow>
        );
      })}
    </>
  );
}

export default GridOverviewTableBody;
