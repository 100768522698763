import { Suspense, useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Chip, Grid, Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import {
  PaginationState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import moment from 'moment';
import GridOverviewTableBody from './GridOverviewTableBody';
import GridOverviewTableHeader from './GridOverviewTableHeader';
import GridOverviewTablePagination from './GridOverviewTablePagination';
import { GridOverviewProps } from './types';

const borderStyle = '1px solid rgba(224, 224, 224, 1)';

const StyledTable = styled(Table)({
  minWidth: 650,
  border: borderStyle,
});

export const getTimestampValue = (value: string) => {
  if (value === null) return '';
  const timestamp = moment(value);
  if (timestamp.isBefore(moment().startOf('D'))) return timestamp.local().format('DD MMMM YYYY HH:mm:ss.SSS');
  else return timestamp.local().format('HH:mm:ss.SSS');
};

export const getTimeAgoString = (value: string) => {
  if (value === null) return '';
  const timestamp = moment(value);
  return timestamp.fromNow();
};

export const getDateValue = (value: string) => {
  if (value === null) return '';
  const timestamp = moment(value);
  if (timestamp.isBefore(moment().startOf('D'))) return <Chip color={'default'} label={timestamp.local().format('DD MMMM YYYY')} />;
  return <Chip color={'info'} sx={{ fontWeight: 'bold' }} label={timestamp.local().format('DD MMMM YYYY')} size={'medium'} />;
};

function GridOverview<TRowModel>({
  data,
  columns,
  title,
  createUri,
  getRowId,
  gridOverviewPaginationState,
  setGridOverviewPaginationState,
}: GridOverviewProps<TRowModel>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: gridOverviewPaginationState?.pageSize ?? 100,
    pageIndex: gridOverviewPaginationState?.pageIndex ?? 0,
  });

  const table = useReactTable({
    data: data ?? [],
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,

    getRowId: getRowId,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),

    // When using parent PaginationState, provide pageCount and manualPagination values
    ...(gridOverviewPaginationState && { pageCount: gridOverviewPaginationState?.pageCount ?? -1, manualPagination: true }),

    // When using local PaginationState, provide getPaginationRowModel from @tanstack/react-table library
    ...(!gridOverviewPaginationState && { getPaginationRowModel: getPaginationRowModel() }),
  });

  // When using parent PaginationState, copy local PaginationState to parent.
  useEffect(() => {
    if (
      gridOverviewPaginationState &&
      setGridOverviewPaginationState &&
      (pagination.pageIndex !== gridOverviewPaginationState.pageIndex || pagination.pageSize !== gridOverviewPaginationState.pageSize)
    ) {
      setGridOverviewPaginationState(pagination);
    }
  }, [pagination, gridOverviewPaginationState, setGridOverviewPaginationState]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <h1>{title}</h1>
          </Grid>

          {createUri ? (
            <Grid item xs={12}>
              <Button variant="contained" startIcon={<AddCircleIcon />} component={Link} to={createUri}>
                Add {title}
              </Button>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <StyledTable aria-label="grid overview">
                <TableHead>
                  <GridOverviewTableHeader table={table} />
                </TableHead>
                <TableBody>
                  <GridOverviewTableBody table={table} />
                </TableBody>
              </StyledTable>
            </TableContainer>
            <GridOverviewTablePagination table={table} pagination={gridOverviewPaginationState ?? pagination} />
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}

export default GridOverview;
