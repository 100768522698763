import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment/moment';
import { useSafetyPredictionServiceGetBerths } from '@/api/ui/queries';
import { BerthSafetyResponse } from '@/api/ui/requests';
import { ReactComponent as SimulationView } from '@/assets/svg/simulation.svg';
import Select from '@/components/common/Select/Select';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import useOrganisation from '@/hooks/useOrganisation';
import usePageTitle from '@/hooks/usePageTitle';
import useSearchParamState from '@/hooks/useSearchParamState';
import { Analytics } from '@/services/analytics';

function HistoricOverview() {
  usePageTitle('Historical simulation', <SimulationView width={32} key={'Historical-simulation'} />);
  const { selectedOrganisationId } = useOrganisation();
  const navigate = useNavigate();
  const [startDate] = useSearchParamState('date', moment().subtract(1, 'hours').startOf('hour').toISOString());
  const [berthId] = useSearchParamState('berthId', null);
  const [numberOfDays] = useSearchParamState('days', 1);
  const [date, setDate] = useState<Moment | null>(moment(startDate));
  const [selectedBerth, setSelectedBerth] = useState<BerthSafetyResponse | null>(null);
  const {
    isError,
    error,
    isLoading,
    data: berths,
  } = useSafetyPredictionServiceGetBerths({
    xSelectedOrganisationId: selectedOrganisationId,
    startTime: startDate,
    numberOfDays: numberOfDays,
  });

  useEffect(() => {
    Analytics.track('Historic-simulation', {});
  }, []);

  useEffect(() => {
    if (berths && berths.length > 0 && berthId) {
      setSelectedBerth(berths.find((berth) => berth.berthId === berthId));
    }
  }, [berths, berthId]);

  const handleChange = useCallback(
    (newValue: Moment | null) => {
      setDate(moment(newValue));
    },
    [setDate]
  );

  if (berths) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box mx={5} my={5}>
          <Grid container item rowSpacing={2} xs={4} alignContent="flex-start">
            <Grid item xs={12}>
              <Typography variant={'body1'} my={2}>
                Select the berth to simulate in the past:
              </Typography>
              <Select<BerthSafetyResponse, BerthSafetyResponse | null>
                label="Berth"
                options={berths.sort((a, b) => a.berthId.localeCompare(b.berthId))}
                value={selectedBerth}
                displayFunction={(berth) => `${berth.berthId} - ${berth.name}`}
                onChange={(berth) => setSelectedBerth(berth)}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} my={2}>
                Select date
              </Typography>
              <DateCalendar value={date} onChange={handleChange} disableFuture={true} displayWeekNumber={true} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/safety-prediction/simulation/berths/${selectedBerth?.berthId}?date=${date?.toISOString()}&mode=historical`)}
              >
                View
              </Button>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    );
  }

  if (isError) {
    return <ApiErrorAlert error={error} />;
  }

  if (!isLoading && berths.length === 0) {
    return (
      <Box>
        <Typography>Sorry, no berths found</Typography>
      </Box>
    );
  }

  return <LoadingIndicator message={'Retrieving berth information for your organisation...'} />;
}

export default HistoricOverview;
