import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAuditTrailServiceGetEvent } from '@/api/management/queries';
import { DomainEvent } from '@/api/management/requests';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';

export interface EventDialogProps {
  open: boolean;
  eventIdentifier: string;
  onClose: (value: string) => void;
}

function EventDialog(props: EventDialogProps) {
  const { onClose, eventIdentifier, open } = props;
  const { data: domainEvent, error } = useAuditTrailServiceGetEvent({ id: eventIdentifier });

  if (error) {
    return <ApiErrorAlert error={error} />;
  }

  const handleClose = () => {
    onClose(eventIdentifier);
  };

  if (domainEvent != null) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          {domainEvent.aggregateType} - {domainEvent.aggregateIdentifier}
        </DialogTitle>
        <DialogContent dividers>
          <AuditTrailEventViewer domainEvent={domainEvent} />
        </DialogContent>
      </Dialog>
    );
  }
  return <LoadingIndicator message={'Retrieving event contents'} />;
}

function AuditTrailEventViewer({ domainEvent }: { domainEvent: DomainEvent }) {
  if (domainEvent != null) {
    return (
      <>
        <DialogContentText>
          <Typography variant="body1" component="pre">
            {JSON.stringify(domainEvent, null, 2)}
          </Typography>
        </DialogContentText>
      </>
    );
  }

  return <LoadingIndicator message={'Retrieving event contents'} />;
}

export default EventDialog;
