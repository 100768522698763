import { Input } from '@mui/material';
import { Column, Table } from '@tanstack/react-table';

function GridOverviewFilter<TRowModel>({ column, table }: { column: Column<TRowModel, unknown>; table: Table<TRowModel> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <Input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])}
        placeholder={`Min`}
        className="w-24 border shadow rounded"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <Input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])}
        placeholder={`Max`}
        className="w-24 border shadow rounded"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  ) : (
    <Input
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="border shadow rounded"
      inputProps={{ 'aria-label': 'search' }}
      fullWidth
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
  );
}

export default GridOverviewFilter;
