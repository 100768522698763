/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { AssignPersonRequest } from '../models/AssignPersonRequest';
import type { Person } from '../models/Person';
import type { RegisterPersonRequest } from '../models/RegisterPersonRequest';
import type { SmartSocietyPerson } from '../models/SmartSocietyPerson';
import type { UpdatePersonPreferencesRequest } from '../models/UpdatePersonPreferencesRequest';

export class PeopleManagementService {
  /**
   * Register a person
   * Registers a person. Either use the person Id (for instance because the user logs into Smart Mooring and cannot be found within
   * Smart Mooring yet), or supply the emailaddress. The details of the person will be requested from Smart Society.
   * The person will automatically be assigned to the Smart Society organisation, so it can access the data for that organisation within Smart Mooring.
   *
   * @param requestBody
   * @returns string The person id that has been registered
   * @throws ApiError
   */
  public static registerPerson(requestBody: RegisterPersonRequest): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/people',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * List all people
   * Retrieve the list of people currently registered within Smart Mooring.
   * @returns Person OK
   * @throws ApiError
   */
  public static listPeople(): CancelablePromise<Array<Person>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/people',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Assign a person
   * Assign a person to one or more organisations. At least assign the person to the Smart Society organisation it belongs to.
   * Also assign it to the additional organisations provided in the request.
   *
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static assignPerson(requestBody: AssignPersonRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/people/allowed-organisations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Save preferences
   * Save the preferences for a person. The preferences are used to configure the application for the user.
   * For instance the language, the units, the default berth etc.
   *
   * @param personId the ID of the person to save the preferences for
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static savePreferences(personId: string, requestBody: UpdatePersonPreferencesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/people/{personId}/preferences',
      path: {
        personId: personId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Find a person
   * Find a person using the personId or emailaddress (not both!) and retrieve the information from Smart Society
   * @param personId the ID of the person to find
   * @param email the email address of the person to find
   * @returns SmartSocietyPerson OK
   * @throws ApiError
   */
  public static findPerson(personId?: string, email?: string): CancelablePromise<SmartSocietyPerson> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/people/smart-society',
      query: {
        personId: personId,
        email: email,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Refresh people
   * Refresh the person information from Smart Society. This is used to update the person information in Smart Mooring
   * when it has been changed in Smart Society.
   *
   * @param personId the ID of the person to refresh, when left empty, all people will be refreshed
   * @returns void
   * @throws ApiError
   */
  public static refreshPeople(personId?: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/people/smart-society/refresh',
      query: {
        personId: personId,
      },
    });
  }
}
