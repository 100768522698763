/// <reference types="@welldone-software/why-did-you-render" />
// import './wdyr';
import { StrictMode, useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from '@/reportWebVitals';
import { appSettings } from '@/services/config';
import reportWebVitalsHandler from '@/services/webVitalsReportHandler';
import SmartMooringApp from './SmartMooringApp';
import './index.css';

const container = document.getElementById('root') as HTMLElement;
const sentryOption = (env: string): Sentry.BrowserOptions => {
  if (env === 'local') {
    return { enabled: false };
  } else {
    return {
      dsn: appSettings.sentry.dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),

        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment: env,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    };
  }
};

Sentry.init(sentryOption(appSettings.sentry.environment));

if (container) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <SmartMooringApp />
    </StrictMode>
  );
}
reportWebVitals(reportWebVitalsHandler);
