import { ChangeEvent, useCallback, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid } from '@mui/material';

export interface DisclaimerDialogProps {
  accepted?: boolean;
  showDialog?: boolean;
  onAccept: (value: boolean) => void;
  onClose: () => void;
}

function DisclaimerDialog({ accepted = false, showDialog = true, onAccept, onClose }: DisclaimerDialogProps) {
  const [checked, setChecked] = useState(accepted || false);
  const buttonText = accepted ? 'Close' : 'Accept';

  const handleClose = useCallback((): void => {
    if (!accepted && checked) {
      onAccept(true);
    }
    onClose();
  }, [onAccept, onClose, accepted, checked]);

  const onCheckBoxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setChecked(event.target.checked);
    },
    [setChecked]
  );

  if (!showDialog) {
    return null;
  }

  return (
    <Dialog open={showDialog} scroll={'paper'} maxWidth={'md'} fullWidth={true}>
      <DialogTitle>Disclaimer</DialogTitle>
      <DialogContent>
        <DialogContentText tabIndex={-1} variant={'body1'} sx={{ marginBottom: 2 }}>
          Royal HaskoningDHV (RHDHV) has developed a Smart Mooring application, also referred to as Smart Mooring Solution. Smart Mooring is an
          independent online application that predicts mooring forces and/or ship motions by combining calculations and weather forecast. With this
          information the user (e.g. a port operator or ship captain) can gain more insight in potentially unsafe situations of a moored ship.
        </DialogContentText>
        <DialogContentText tabIndex={-1} variant={'body1'} sx={{ marginBottom: 2 }}>
          You acknowledge that the Smart Mooring application is for <b>decision support only</b>. You assume sole responsibility for results obtained
          from the use of the Smart Mooring application, and for conclusions drawn from such use.
        </DialogContentText>
        <DialogContentText tabIndex={-1} variant={'body1'} sx={{ marginBottom: 2 }}>
          The estimates in the Smart Mooring application are based on data obtained from the client as well as from third parties. RHDHV makes every
          effort to build and maintain the presented information in the Smart Mooring application as up-to-date, accessible, error-free and complete
          as possible. However, RHDHV does <b>not guarantee the accuracy and completeness</b> of the information. Royal HaskoningDHV will not be
          responsible or hold any liability regarding the accuracy and the validity of this <b>obtained data</b>.
        </DialogContentText>
        <DialogContentText tabIndex={-1} variant={'body1'} sx={{ marginBottom: 2 }}>
          The Smart Mooring application is owned by RHDHV who reserves all <b>intellectual property rights</b> therein (including but not limited to
          all copyright) and you agree that you will not do anything to infringe or prejudice those rights.
        </DialogContentText>
        <DialogContentText tabIndex={-1} variant={'body1'} sx={{ marginBottom: 2 }}>
          RHDHV will not be responsible or hold any <b>liability</b> whatsoever regarding, the use of, Smart Mooring application and expressly
          disclaims any and all liability for errors and omissions in its contents.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {!accepted && (
            <Grid item>
              <FormControlLabel control={<Checkbox onChange={onCheckBoxChange} />} label="I have read the disclaimer" />
            </Grid>
          )}
          <Grid item flex={1}></Grid>
          <Grid item alignItems={'flex-end'}>
            <Button onClick={handleClose} color={'primary'} variant={'contained'} size={'small'} disabled={!checked}>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DisclaimerDialog;
