import { ReactElement, useContext, useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Chip, Container as MuiContainer, IconButton as MuiIconButton, Toolbar, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import AppLogo from '@/components/common/AppLogo/AppLogo';
import ButtonWrapper from '@/components/common/Button/ButtonWrapper';
import Divider from '@/components/common/Divider/Divider';
import { MenuItemGroupProps } from '@/components/common/Menu/types';
import SmartMooringContext from '@/features/Common/SmartMooringContext';
import SideBarMenu from '@/parts/Sidebar/SideBarMenu';
import UserMenu, { userMenuItems } from '@/parts/UserMenu/UserMenu';
import { appSettings } from '@/services/config';

interface NavigationConfiguration {
  menuGroups: MenuItemGroupProps[];
  hideSupportMenuGroup?: boolean;
}

const IconButton = styled(MuiIconButton)(() => ({
  '& svg': { fontSize: 28 },
}));

const TopBarContainer = styled(MuiContainer)(() => ({
  maxHeight: 54,
}));

function TopNavigationBar({ menuGroups, hideSupportMenuGroup }: NavigationConfiguration) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const smartMooringContext = useContext(SmartMooringContext);
  const [pageTitle, setPageTitle] = useState(document.title);
  const [pageIcon, setPageIcon] = useState<ReactElement | undefined>(undefined);

  useEffect(() => {
    setPageTitle(smartMooringContext.pageTitle);
  }, [smartMooringContext.pageTitle]);

  useEffect(() => {
    setPageIcon(smartMooringContext.pageTitleIcon);
  }, [smartMooringContext.pageTitleIcon]);

  return (
    <>
      <AppBar id="smartmooring-app-bar" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <TopBarContainer disableGutters={true} maxWidth={false}>
          <Toolbar disableGutters variant={'dense'}>
            <ButtonWrapper
              component={IconButton}
              size="large"
              aria-label="application menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setIsOpen(!isOpen)}
              color="inherit"
              title={'application menu'}
              analyticsEventName="SM app bar"
              analyticsEventProperties={{ open: !isOpen }}
              sx={{ width: '46px' }}
            >
              <MenuIcon />
            </ButtonWrapper>
            <Divider
              orientation={'vertical'}
              sx={{
                borderWidth: '1px',
                borderColor: '#ddd !important',
                height: 'inherit',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            />
            <Box sx={{ flexGrow: 1, paddingTop: 1 }} display={'flex'}>
              <Box sx={{ paddingLeft: 1, paddingRight: 1 }}>{pageIcon}</Box>&nbsp;
              {pageTitle}
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex', position: 'absolute', left: '50%', transform: 'translateX(-50%)' } }}>
              <AppLogo />
              {appSettings.deployment?.toLowerCase() !== 'prod' && (
                <Chip color={'info'} sx={{ fontWeight: 'bold' }} title={appSettings.deployment} label={appSettings.deployment.toUpperCase()} />
              )}
            </Box>

            <UserMenu menuGroups={userMenuItems} />
          </Toolbar>
        </TopBarContainer>
      </AppBar>
      <SideBarMenu menuGroups={menuGroups} isOpen={isOpen} hideSupportMenuGroup={hideSupportMenuGroup} />
    </>
  );
}

export default TopNavigationBar;
