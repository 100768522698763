import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '@auth0/auth0-spa-js';
import { authNamespace } from '@/features/Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '@/features/Authentication/Auth0Types';
import { Analytics } from '@/services/analytics';

function initializeTracking(user: User | undefined) {
  if (!user) return false;
  Analytics.initialize();

  return true;
}

const AnalyticsSmartMooring = () => {
  const { user } = useAuth0();
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);

  useEffect(() => {
    if (!analyticsLoaded) {
      setAnalyticsLoaded(initializeTracking(user));
    }
  }, [user, analyticsLoaded]);

  if (user) {
    const userProfile: CustomClaims = user?.[authNamespace];
    Analytics.organisation(userProfile.organization_id);
    Analytics.setUserProfile(userProfile.person_id, userProfile.organization_name);
  }

  return <></>;
};

export default AnalyticsSmartMooring;
