/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { MooredVessel } from '../models/MooredVessel';

export class VesselManagementService {
  /**
   * Returns all currently moored vessels.
   * @returns MooredVessel OK
   * @throws ApiError
   */
  public static getMooredVessels(): CancelablePromise<Array<MooredVessel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vessels/moored',
    });
  }

  /**
   * Manually unmoors a vessel.
   * @param visitId
   * @returns void
   * @throws ApiError
   */
  public static unmoorVessel(visitId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/vessels/moored/{visitId}',
      path: {
        visitId: visitId,
      },
    });
  }
}
