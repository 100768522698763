import { MouseEvent, useCallback, useState } from 'react';
import { AccountCircle, DirectionsBoat, Info, LibraryBooks } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TuneIcon from '@mui/icons-material/Tune';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, Menu } from '@mui/material';
import AppMenuGroup from '@/components/common/Menu/AppMenuGroup';
import AppMenuOrganisationSwitch from '@/components/common/Menu/AppMenuOrganisationSwitch';
import { MenuItemGroupProps } from '@/components/common/Menu/types';
import UserMenuHandle from '@/parts/UserMenu/UserMenuHandle';
import css from './index.module.css';

export const ROLE_SYSTEM_ADMINISTRATOR = 'System Administrator';
export const ROLE_CONFIGURATION_ADMINISTRATOR = 'Configuration Administrator';
export const ROLE_DMA_ENGINEER = 'DMA Engineer';
export const ROLE_BASIC_USER = 'Basic User';

export const userMenuItems: MenuItemGroupProps[] = [
  {
    title: 'Profile',
    showTitle: false,
    showDividerAfter: true,
    items: [
      {
        displayText: 'Profile',
        description: 'Show my user profile data',
        icon: <AccountCircle />,
        linkTo: '/profile',
      },
      {
        displayText: 'Smart Society account',
        description: 'Go to the Smart Society account portal to change your name, password etc.',
        icon: <ManageAccountsIcon />,
        externalLinkTo: 'https://account.smart-society.io/account-settings',
      },
      {
        displayText: 'Access token',
        description: 'Get the current access token, for development use only!',
        icon: <VpnKeyIcon />,
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR],
        linkTo: '/token',
      },
    ],
  },
  {
    title: 'Configuration',
    showTitle: false,
    showDividerAfter: true,
    allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER],
    items: [
      {
        displayText: 'Configuration',
        description: 'Go to the configuration section',
        icon: <TuneIcon />,
        linkTo: '/admin/organisations',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER],
      },
    ],
  },
  {
    title: 'About',
    showTitle: false,
    showDividerAfter: true,
    items: [
      {
        displayText: 'Vessel classes',
        description: 'Overview of vessel classes within Smart Mooring',
        icon: <DirectionsBoat />,
        linkTo: '/vessel-classes',
      },
      {
        displayText: 'DMA case library',
        description: 'Overview of DMA cases within Smart Mooring',
        icon: <LibraryBooks />,
        linkTo: '/dma-case-library',
      },
      {
        displayText: 'About',
        description: 'About Smart Mooring',
        icon: <Info />,
        linkTo: '/about',
      },
    ],
  },
  {
    title: 'Logout',
    showTitle: false,
    showDividerAfter: false,
    items: [
      {
        displayText: 'Logout',
        description: 'Logout from Smart Mooring',
        icon: <Logout />,
        linkTo: '/logout',
      },
    ],
  },
];

interface UserMenuProperties {
  menuGroups: MenuItemGroupProps[];
}

function UserMenu({ menuGroups }: UserMenuProperties) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    [setAnchorElUser]
  );

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, [setAnchorElUser]);

  return (
    <>
      <Box sx={{ flexGrow: 0 }} className={css.userMenu}>
        <UserMenuHandle menuOpenHandler={handleOpenUserMenu} />

        <Menu
          sx={{ mt: '10px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          keepMounted
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 0.2,
              '& .MuiSvgIcon-root': {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <AppMenuOrganisationSwitch />
          {menuGroups.map((group, index) => (
            <AppMenuGroup {...{ ...group, collapsed: !anchorElUser }} key={index} />
          ))}
        </Menu>
      </Box>
    </>
  );
}

export default UserMenu;
