/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { HistoricMetoceanDataRequest } from '../models/HistoricMetoceanDataRequest';
import type { MetoceanStation } from '../models/MetoceanStation';
import type { RefreshMetoceanForecastsRequest } from '../models/RefreshMetoceanForecastsRequest';

export class MetoceanStationManagementService {
  /**
   * List metocean stations
   * Retrieve the list of metocean stations that are configured in the system.
   * @returns MetoceanStation OK
   * @throws ApiError
   */
  public static listMetoceanStations(): CancelablePromise<Array<MetoceanStation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metocean-stations',
    });
  }

  /**
   * Upload historic metocean data
   * Upload historic metocean data to be able to create statistics in the vessel clearance and show historic safety warnings.
   * The data is uploaded in bulk, so for a period of 1 day, 1 week, 1 month etc.
   *
   * @param metoceanProvider
   * @param metoceanStation
   * @param formData The historic metocean data to upload
   * @returns void
   * @throws ApiError
   */
  public static uploadHistoricMetoceanData(
    metoceanProvider: string,
    metoceanStation: string,
    formData: {
      file?: Blob;
    }
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/metocean-stations/{metoceanProvider}/{metoceanStation}/historic-forecasts',
      path: {
        metoceanProvider: metoceanProvider,
        metoceanStation: metoceanStation,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * Generate historic warnings
   * Generate historic warnings for the metocean station by retrieving the historic metocean data
   * and using that data to generate the safety warnings.
   *
   * @param requestBody The date range for which to generate historic warnings
   * @returns any OK
   * @throws ApiError
   */
  public static generateHistoricWarnings(requestBody: HistoricMetoceanDataRequest): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/metocean-stations/historic-warnings',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Refresh forecasts
   * Refresh the forecasts for the metocean station(s) so that new safety predictions will be determined.
   * When no provider and station ID are specified, all metocean stations will be refreshed.
   * By default for today and a period of 5 days, unless a date is specified. When a date is specified, that is the start of the 5 day period.
   *
   * @param requestBody The date for which to refresh the forecasts (and a period of 5 days after that date)
   * @returns void
   * @throws ApiError
   */
  public static refreshForecasts(requestBody?: RefreshMetoceanForecastsRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/metocean-stations/refresh-forecasts',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
