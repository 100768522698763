/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { FeedbackRequest } from '../models/FeedbackRequest';

export class FeedbackService {
  /**
   * Send feedback
   * Send feedback to the development team using a web hook to send it to MS Teams. Include a screenshot for visual reference.
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static sendFeedback(requestBody: FeedbackRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/feedback',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }
}
