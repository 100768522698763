import React, { useCallback, useEffect, useState } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel/TabPanel';
import { Box, Button, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from '@mui/material';
import { User, useAuth0 } from '@auth0/auth0-react';
import moment from 'moment/moment';
import { useProfileServiceGetDmaCases } from '@/api/ui/queries';
import { OrganisationSummary } from '@/api/ui/requests';
import { authNamespace } from '@/features/Authentication/Auth0ProviderSmartMooring';
import { CustomClaims, LicenseAgreement } from '@/features/Authentication/Auth0Types';
import UserPreferences from '@/features/Authentication/UserPreferences';
import useLicence from '@/hooks/useLicence';
import useOrganisation from '@/hooks/useOrganisation';
import usePageTitle from '@/hooks/usePageTitle';
import { DmaCaseVesselType } from '@/pages/DmaCaseLibrary/DmaCaseLibraryOverview';
import { ROLE_SYSTEM_ADMINISTRATOR } from '@/parts/UserMenu/UserMenu';
import { appSettings } from '@/services/config';

interface DmaCasesProps {
  selectedOrganisationId: string;
}

function DmaCases({ selectedOrganisationId }: DmaCasesProps): React.JSX.Element {
  const { data: dmaCases } = useProfileServiceGetDmaCases({ xSelectedOrganisationId: selectedOrganisationId });
  if (!dmaCases) return <></>;

  return (
    <>
      <Grid container spacing={6} pt={5}>
        {dmaCases.map((vesselTypeDmaCases) => (
          <DmaCaseVesselType
            key={vesselTypeDmaCases.vesselType}
            vesselType={vesselTypeDmaCases.vesselType}
            dmaCaseDefinitions={vesselTypeDmaCases.dmaCaseDefinitions}
            showSpecific={true}
          />
        ))}
      </Grid>
    </>
  );
}

function Profile() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState<string | null>(null);
  const userProfile: CustomClaims = user?.[authNamespace];
  const { selectedOrganisation, organisationId, organisationName, selectedOrganisationId } = useOrganisation();
  const { hasRole, features } = useLicence();
  const isSystemAdministrator = hasRole([ROLE_SYSTEM_ADMINISTRATOR]);

  const [value, setValue] = useState('personal');
  usePageTitle('User profile - Smart Mooring');

  const getUserMetadata = useCallback(() => {
    try {
      getAccessTokenSilently({
        authorizationParams: { scope: `profile email ${appSettings.authentication.scopes}` },
      }).then((token) => {
        setUserMetadata(token);
      });
    } catch (e) {
      console.error('Error getting user token', e);
    }
  }, [getAccessTokenSilently, setUserMetadata]);

  useEffect(() => {
    getUserMetadata();
  }, [getUserMetadata, user?.sub]);

  interface OrganisationProps {
    organisationName: string;
  }

  function OrganisationName({ organisationName }: OrganisationProps) {
    return (
      <Paper title={'Organisation information'} sx={{ width: '100%', minWidth: '250px', maxWidth: '50vw' }} elevation={0}>
        <TextField
          fullWidth={true}
          type={'text'}
          label={'Name'}
          variant={'standard'}
          focused
          value={organisationName}
          color={'primary'}
          InputProps={{
            readOnly: true,
          }}
        />
      </Paper>
    );
  }

  function showOrganisation(profile: CustomClaims, selectedOrganisation: OrganisationSummary, selectedOrganisationId: string) {
    return (
      <>
        <h3>Organisation</h3>
        <OrganisationName organisationName={profile.organization_name} />
        {profile.organization_id != selectedOrganisationId && (
          <>
            <h3>Selected organisation</h3>
            <OrganisationName organisationName={selectedOrganisation?.name} />
          </>
        )}
        {selectedOrganisationId && (
          <>
            <h3>DMA Cases</h3>
            <p>
              Overview of Dynamic Mooring Analysis matrices currently configured for the minimum of one berth of the current organisation (
              {selectedOrganisation?.name}).
            </p>
            <DmaCases selectedOrganisationId={selectedOrganisationId} />
          </>
        )}
      </>
    );
  }

  function showProfileData(user: User) {
    return (
      <>
        <h3>User information</h3>
        <Paper title={'User information'} sx={{ width: '100%', minWidth: '250px', maxWidth: '50vw' }} elevation={0}>
          <TextField
            fullWidth={true}
            type={'text'}
            label={'Name'}
            variant={'standard'}
            focused
            value={user.name}
            size={'medium'}
            color={'primary'}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth={true}
            value={user?.email}
            size={'medium'}
            variant={'standard'}
            color={'primary'}
            type={'text'}
            InputProps={{
              readOnly: true,
            }}
            label={'Email address'}
          />
          <TextField
            fullWidth={true}
            value={user?.locale}
            size={'medium'}
            variant={'standard'}
            color={'primary'}
            type={'text'}
            InputProps={{
              readOnly: true,
            }}
            label={'Locale'}
          />
          <TextField
            fullWidth={true}
            value={user?.zoneinfo}
            size={'medium'}
            variant={'standard'}
            color={'primary'}
            type={'text'}
            InputProps={{
              readOnly: true,
            }}
            label={'Timezone'}
          />
          <TextField
            fullWidth={true}
            value={moment(user?.updated_at).format('DD-MM-YYYY HH:mm:ss')}
            size={'medium'}
            variant={'standard'}
            color={'primary'}
            type={'text'}
            InputProps={{
              readOnly: true,
            }}
            label={'Last login'}
          />
        </Paper>
        <UserPreferences />
        <Box marginTop={4}>
          <Button
            startIcon={<AccountCircle />}
            color={'primary'}
            variant={'contained'}
            title={'Smart Society account portal'}
            href={'https://account.smart-society.io/account-settings'}
            target={'_blank'}
          >
            Change Smart Society account details
          </Button>
        </Box>
      </>
    );
  }

  function showLicenseAgreement(licenseAgreement: LicenseAgreement) {
    const licenses = licenseAgreement.products?.filter((product) => product.name === appSettings.license.productName);
    const roles = licenses?.flatMap((license) => license.roles);
    if (licenses.length == 0) return <></>;

    return (
      <Box sx={{ padding: '20px', minWidth: '250px', maxWidth: '50vw' }} key={`licenses-${organisationName}`}>
        <h3>
          Has {licenses.length} license(s) for {organisationName}
        </h3>
        <p>The following features are available within the licenses.</p>
        <TableContainer component={Paper} sx={{ maxWidth: '50vw' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow key={'Features-header'}>
                <TableCell>
                  <b>Features</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {features.map((feature) => (
                <TableRow key={feature} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="td" scope="row">
                    {feature}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <p />
        <p>You have the following roles assigned to you within the Smart Mooring application:</p>
        <TableContainer component={Paper} sx={{ maxWidth: '50vw' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow key={'Role-header'}>
                <TableCell>
                  <b>Roles</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((row) => (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="td" scope="row">
                    {row.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }

  if (!user) {
    return null;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box alignItems="center" width={'100%'} gap={'3px'}>
      <Box sx={{ padding: '20px', minWidth: '250px' }}>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary" aria-label="secondary tabs example">
            <Tab value="personal" label="Personal" />
            <Tab value="organisation" label="Organisation" />
            <Tab value="license" label="License" />
            {isSystemAdministrator && <Tab value="System config" label="System config" />}
          </Tabs>
          <TabPanel value="organisation">{userMetadata && showOrganisation(userProfile, selectedOrganisation, selectedOrganisationId)}</TabPanel>
          <TabPanel value="personal">{showProfileData(user)}</TabPanel>
          <TabPanel value="license">
            {userProfile?.license_agreements &&
              userProfile?.license_agreements.length > 0 &&
              showLicenseAgreement(userProfile.license_agreements.filter((license) => license.organization_id === organisationId).pop())}
          </TabPanel>
          {isSystemAdministrator && (
            <TabPanel value="System config">
              <h3>System config</h3>
              <p>API url: {appSettings.api.baseUrl}</p>
              <p>Deployment: {appSettings.deployment}</p>
              <p>Mode: {appSettings.environment}</p>
              <p>Licensed product name: {appSettings.license.productName}</p>
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </Box>
  );
}

export default Profile;
