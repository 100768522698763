import { Divider, List, ListItemIcon, ListItemText, ListSubheader, Stack, Tooltip } from '@mui/material';
import useLicence from '@/hooks/useLicence';
import { appSettings } from '@/services/config';
import AppMenuItem from './AppMenuItem';
import { MenuItemGroupProps } from './types';

function AppMenuGroup(group: MenuItemGroupProps) {
  const { hasRole } = useLicence();

  const subHeaderCollapsed = group.icon ? (
    <ListSubheader component="div" disableGutters={true} disableSticky={true} inset={false}>
      <Tooltip title={group.title} arrow={true}>
        <ListItemIcon sx={{ justifyContent: 'normal', paddingTop: 1, paddingLeft: 1, color: '#5eb5fe' }}>{group.icon}</ListItemIcon>
      </Tooltip>
    </ListSubheader>
  ) : (
    <></>
  );
  const subHeaderExpanded = group.icon ? (
    <ListSubheader
      disableGutters={true}
      disableSticky={true}
      inset={false}
      sx={{
        justifyContent: group.collapsed ? 'center' : 'initial',
      }}
    >
      <Stack direction={'row'}>
        <ListItemIcon sx={{ justifyContent: 'normal', paddingTop: 1, paddingLeft: 1, color: '#5eb5fe' }}>{group.icon}</ListItemIcon>
        <ListItemText disableTypography={true} primary={group.title} sx={{ opacity: group.collapsed ? 0 : 1 }} />
      </Stack>
    </ListSubheader>
  ) : (
    <ListSubheader component="div">
      <ListItemText primary={group.title} sx={{ opacity: group.collapsed ? 0 : 1 }} />
    </ListSubheader>
  );

  function getSubHeader() {
    if (!group.showTitle) return <></>;
    return group.collapsed ? subHeaderCollapsed : subHeaderExpanded;
  }

  function isMenuVisible(menu: MenuItemGroupProps) {
    return !menu.allowedRoles || Array.isArray(menu.allowedRoles) ? hasRole(menu.allowedRoles) : true;
  }

  return (
    <>
      {isMenuVisible(group) && group.items && (
        <List sx={{ width: '100%' }} component="nav" aria-labelledby="nested-list-subheader" subheader={getSubHeader()}>
          {group.items
            .filter((item) => (item.featureFlag ? appSettings.environment !== 'production' : true))
            .map((item) => (
              <AppMenuItem key={item.displayText} {...{ ...item, collapsed: group.collapsed }} />
            ))}
        </List>
      )}

      {group.showDividerAfter && <Divider />}
    </>
  );
}

export default AppMenuGroup;
