import { Box, Grid, Typography } from '@mui/material';
import cartoon from '@/assets/images/notfound.png';

function NotFound() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
      <Grid container direction={'row'}>
        <Grid item md={5} pl={10}>
          <h1>Not found....</h1>
          <Typography variant={'bodyRegularMedium'} component={'p'}>
            Unfortunately something is wrong. The page you were looking for cannot be found. Try again or contact us so we can help you solve the
            problem.
          </Typography>
          <Typography variant={'bodyRegularMedium'} component={'p'} marginTop={2}>
            You can contact us by sending an email to:
            <br />
            <a href={'mailto:smart.mooring.support@royalhaskoningdhv.com'}>smart.mooring.support@royalhaskoningdhv.com</a>
          </Typography>
        </Grid>
        <Grid item md={7}>
          <img src={cartoon} alt={'Not found'} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default NotFound;
