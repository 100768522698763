import { Dispatch, SetStateAction } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FeedbackDialog } from 'mui-feedback-dialog';
import { SnackbarMessage, useSnackbar } from 'notistack';
import { useFeedbackServiceSendFeedback } from '@/api/ui/queries';
import useOrganisation from '@/hooks/useOrganisation';

const customText = {
  title: 'Your feedback',
  contentText:
    'Thank you for giving feedback, this helps us to improve the product. Please describe any issue as detailed as possible. ' +
    'If it is okay to contact you for more details, please enter your email address as well.',
};

interface FeedbackProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function FeedbackComponent({ isOpen, setOpen }: FeedbackProps) {
  const { mutate: feedback, error, isError } = useFeedbackServiceSendFeedback();
  const organisation = useOrganisation();
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  if (isError) {
    enqueueSnackbar(error as SnackbarMessage, {
      variant: 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'left', vertical: 'top' },
      className: 'feedback-error',
      style: { backgroundColor: 'red' },
      autoHideDuration: 5000,
    });
  }
  const onSubmit = (data: { description: string; email: string; screenshot?: string }) => {
    feedback.call(undefined, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      requestBody: {
        email: data.email,
        description: data.description,
        path: window.location.href,
        organisation: organisation.organisationName,
      },
    });
    feedback.call(undefined, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      requestBody: {
        email: data.email,
        description: data.description,
        path: window.location.href,
        organisation: organisation.organisationName,
        screenshot: data.screenshot,
      },
    });
  };

  return (
    <FeedbackDialog
      text={customText}
      open={isOpen}
      onClose={() => setOpen(false)}
      onSubmit={onSubmit}
      initialEmail={user?.email}
      attachScreenshotOnOpen={true}
      useScreencapture={true}
    />
  );
}

export default FeedbackComponent;
