import { useEffect, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';
import GridOverview from './GridOverview';
import { GridOverviewPaginationState, PagedGridOverviewProps } from './types';

function PagedGridOverview<TRowModel>({
  columns,
  getRowId,
  title,
  createUri,
  data,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  pageCount,
  rowCount,
}: PagedGridOverviewProps<TRowModel>) {
  const [pagination, setPagination] = useState<GridOverviewPaginationState>({
    pageSize: pageSize,
    pageIndex: pageNumber - 1,
    rowCount: rowCount,
    pageCount: pageCount,
  });

  useEffect(() => {
    setPagination({
      pageSize: pageSize,
      pageIndex: pageNumber - 1,
      pageCount: pageCount,
      rowCount: rowCount,
    });
  }, [pagination.pageIndex, pageCount, pageNumber, pageSize, rowCount, setPageNumber]);

  return (
    <GridOverview
      columns={columns}
      getRowId={getRowId}
      title={title}
      createUri={createUri}
      data={data}
      gridOverviewPaginationState={pagination}
      setGridOverviewPaginationState={(value: PaginationState) => {
        setPagination({
          pageSize: value.pageSize,
          pageIndex: value.pageIndex,
          pageCount: pagination.pageCount,
          rowCount: pagination.rowCount,
        });

        if (pageNumber !== value.pageIndex + 1) {
          setPageNumber(value.pageIndex + 1);
        }
        if (pageSize !== value.pageSize) {
          setPageSize(value.pageSize);
        }
      }}
    />
  );
}

export default PagedGridOverview;
