export const formatNoDecimals = (value: number): number => parseInt(value.toFixed(0), 10);

export const formatSingleDecimal = (value: number): number => parseInt(value.toFixed(1), 10);

// Round number to one decimal
export function roundNumber(number: number): string {
  const rounded = Math.round(number * 10) / 10;
  return rounded === number ? number.toString() : rounded.toFixed(1);
}

// transform numbers to localeStrings (1000 comma and dot separator)
export function numberToLocaleString(
  num: number | undefined,
  locales?: string | string[] | undefined,
  amountOfDigits: number | undefined = 0
): string {
  if (num === undefined || num === null) return '';
  return num.toLocaleString(
    locales, // leave undefined to use the browser's locale,
    // or use a string like 'en-US' to override it.
    { maximumFractionDigits: amountOfDigits }
  );
}

export function meterPerSeccondToKnots(m: number | undefined): number {
  return (m ?? 0) * 1.9438444924406;
}
