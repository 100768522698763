import { HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Box, FilterOptionsState, ListItem, Paper, TextField, createFilterOptions } from '@mui/material';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { AutoCompleteProps } from '@/components/common/AutoComplete/types';
import { numberToLocaleString } from '@/utils/numberFormatUtils';

function AutoComplete<T>({
  options,
  value,
  setValue,
  displayFunction,
  startAdornment,
  optionsLimit,
  placeHolder,
  equalsFunction,
}: AutoCompleteProps<T>) {
  const [inputValue, setInputValue] = useState<string>('');

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, item: T): JSX.Element => {
      return <ListItem {...props}>{displayFunction(item)}</ListItem>;
    },
    [displayFunction]
  );

  useEffect(() => {
    setInputValue(value ? displayFunction(value) : '');
  }, [displayFunction, value]);

  const defaultFilterOptions = createFilterOptions<T>();

  const filterOptions = useCallback(
    (options: T[], state: FilterOptionsState<T>) => {
      const filteredOptions = defaultFilterOptions(options, state);

      return optionsLimit ? filteredOptions.slice(0, optionsLimit) : filteredOptions;
    },
    [optionsLimit, defaultFilterOptions]
  );

  interface NumResultsHeaderProps extends HTMLAttributes<HTMLElement> {
    children?: ReactNode;
  }

  const NumResultsHeader = ({ children, ...props }: NumResultsHeaderProps) => {
    const countRef = useRef<HTMLSpanElement | null>(null);
    const paperRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      const numOptions = paperRef.current?.querySelectorAll('li[data-option-index]').length || 0;
      if (countRef.current != null) {
        countRef.current.innerHTML = numberToLocaleString(numOptions, 'en-US');
      }
    });
    return (
      <>
        <Paper {...props} ref={paperRef}>
          <Box display="flex" justifyContent="center">
            <span>
              Showing <span ref={countRef} /> of {options.length} options
            </span>
          </Box>
          {children}
        </Paper>
      </>
    );
  };

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField
        {...params}
        placeholder={placeHolder}
        InputProps={{
          ...params.InputProps,
          startAdornment: startAdornment,
        }}
      />
    ),
    [placeHolder, startAdornment]
  );

  const isOptionEqualToValue = useCallback(
    (option: T, value: T) => (equalsFunction ? equalsFunction(option, value) : displayFunction(option) === displayFunction(value)),
    [equalsFunction, displayFunction]
  );

  const onInputChange = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      event != null && event.stopPropagation();
      setInputValue(value);
    },
    [setInputValue]
  );

  const onChange = useCallback(
    (event: React.SyntheticEvent, value: T | null) => {
      event != null && event.stopPropagation();
      setValue(value);
    },
    [setValue]
  );

  const getOptionLabel = useCallback((option: T) => (option ? displayFunction(option) : ''), [displayFunction]);

  return (
    <Autocomplete
      options={options}
      inputValue={inputValue}
      filterOptions={filterOptions}
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={onInputChange}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      PaperComponent={optionsLimit ? NumResultsHeader : undefined}
      renderInput={renderInput}
    />
  );
}

export default AutoComplete;
