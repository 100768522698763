import { AccountCircle } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import NotFoundImage from '@/assets/images/notfound.png';
import SmartMooringLogo from '@/assets/images/smartmooring-logo.png';
import TwinnLogo from '@/assets/images/twinn-logo.png';
import { authNamespace } from '@/features/Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '@/features/Authentication/Auth0Types';

function NoLicense() {
  const { user: auth0User, logout } = useAuth0();
  const auth0UserProfile: CustomClaims | undefined = auth0User?.[authNamespace];

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
      <Grid container direction={'row'}>
        <Grid item xs={12}>
          <Box marginBottom={6} marginLeft={10}>
            <Grid container direction={'row'} alignItems={'center'}>
              <Grid item xs={9}>
                <img src={SmartMooringLogo} width={300} alt={'Smart Mooring logo'} title={'Smart Mooring logo'} />
              </Grid>
              <Grid item xs={3}>
                <img src={TwinnLogo} width={200} alt={'Twinn logo'} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={5} pl={10}>
          <Stack direction={'column'}>
            <Box>
              <h1>No license found</h1>
              <Typography variant={'bodyRegularMedium'} component={'p'}>
                No active license for Smart Mooring was found for currently logged in user <b>{auth0User?.name}</b>&nbsp;(
                <b>{auth0UserProfile?.organization_name}</b>).
                <br />
                When you previously had a trial access account, please contact us to request a new trial period.
              </Typography>
              <Typography variant={'bodyRegularMedium'} component={'p'} marginTop={2}>
                Please contact us so we can help you fix this issue with your license. You can contact us by sending an email to &nbsp;
                <a href={'mailto:smart.mooring.support@royalhaskoningdhv.com'}>smart.mooring.support@royalhaskoningdhv.com</a>
              </Typography>
              <Typography variant={'bodyRegularMedium'} component={'p'} marginTop={2}>
                For more information about Smart Mooring, check our product site&nbsp;
                <a href={'https://www.twinn.io/en/services/smart-mooring'}>https://www.twinn.io/en/services/smart-mooring</a>
              </Typography>
            </Box>
            <Box marginTop={4}>
              <Button startIcon={<Logout />} color={'primary'} variant={'contained'} title={'Logout'} onClick={() => logout()}>
                Logout
              </Button>
              <Button
                startIcon={<AccountCircle />}
                sx={{ marginLeft: 20 }}
                color={'primary'}
                variant={'contained'}
                title={'Smart Society account portal'}
                href={'https://account.smart-society.io'}
              >
                Smart Society
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={7}>
          <img src={NotFoundImage} alt={'License for Smart Mooring not found'} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default NoLicense;
