import { VesselClassDefinition } from '@/api/ui/requests';
import { numberToLocaleString } from '@/utils/numberFormatUtils';

export function classifictionCriteria(dmaCaseDefinition: VesselClassDefinition) {
  if (dmaCaseDefinition.minimumValue && dmaCaseDefinition.maximumValue) {
    return `${numberToLocaleString(dmaCaseDefinition.minimumValue)} - ${numberToLocaleString(dmaCaseDefinition.maximumValue)} ${
      dmaCaseDefinition.unit
    }`;
  } else if (dmaCaseDefinition.minimumValue) {
    return `≥ ${numberToLocaleString(dmaCaseDefinition.minimumValue)} ${dmaCaseDefinition.unit}`;
  } else if (dmaCaseDefinition.maximumValue) {
    return `≤ ${numberToLocaleString(dmaCaseDefinition.maximumValue)} ${dmaCaseDefinition.unit}`;
  } else {
    return 'No classification';
  }
}
