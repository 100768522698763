/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { BandwidthOption } from '../models/BandwidthOption';
import type { BerthAssignment } from '../models/BerthAssignment';
import type { BerthDmaCaseSafetyPrediction } from '../models/BerthDmaCaseSafetyPrediction';
import type { BerthSafetyResponse } from '../models/BerthSafetyResponse';
import type { DmaCase } from '../models/DmaCase';
import type { DmaCaseBaseOfSimulation } from '../models/DmaCaseBaseOfSimulation';
import type { LoadingCondition } from '../models/LoadingCondition';
import type { MooredVessel } from '../models/MooredVessel';
import type { MotionType } from '../models/MotionType';
import type { SafetyPredictionsResponse } from '../models/SafetyPredictionsResponse';
import type { VesselDisposition } from '../models/VesselDisposition';

export class SafetyPredictionService {
  /**
   * Get base of simulation
   * Get the base of simulation for a DMA case. This information is used as basis for the Dynamic Mooring Analysis.
   * Information like the wind area, fender type, line type etc.
   *
   * @param xSelectedOrganisationId
   * @param dmaCaseIdentifier
   * @returns DmaCaseBaseOfSimulation OK
   * @throws ApiError
   */
  public static getBaseOfSimulation(xSelectedOrganisationId: string, dmaCaseIdentifier: string): CancelablePromise<DmaCaseBaseOfSimulation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/dma-cases/{dmaCaseIdentifier}/base-of-simulation',
      path: {
        dmaCaseIdentifier: dmaCaseIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get berths
   * Retrieve the berths for the selected organisation.
   * When a user has access to multiple organisations the organisation passed in the header is used to retrieve the berths for.
   * The information can be retrieved for a number of days and for a specific start date.
   * When no historic view is needed specify the current time rounded to the hour.
   * When no numberOfDays is specified, 1 day is used.
   *
   * @param xSelectedOrganisationId
   * @param startTime
   * @param numberOfDays
   * @returns BerthSafetyResponse OK
   * @throws ApiError
   */
  public static getBerths(xSelectedOrganisationId: string, startTime: string, numberOfDays?: number): CancelablePromise<Array<BerthSafetyResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        startTime: startTime,
        numberOfDays: numberOfDays,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get berth safety prediction
   * Retrieve the berth with it's safety prediction for the selected organisation.
   * When a user has access to multiple organisations the organisation passed in the header is used to retrieve the berth for.
   *
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @returns BerthAssignment OK
   * @throws ApiError
   */
  public static getBerth(xSelectedOrganisationId: string, berthIdentifier: string): CancelablePromise<BerthAssignment> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}',
      path: {
        berthIdentifier: berthIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get assigned DMA cases
   * Get the assigned DMA cases for this berth and the specified organisation.
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @returns DmaCase OK
   * @throws ApiError
   */
  public static getBerthDmaCases(xSelectedOrganisationId: string, berthIdentifier: string): CancelablePromise<Array<DmaCase>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/dma-cases',
      path: {
        berthIdentifier: berthIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get assigned DMA cases with the maximum prediction for the requested period
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @param startTime
   * @param numberOfDays
   * @returns BerthDmaCaseSafetyPrediction OK
   * @throws ApiError
   */
  public static getBerthDmaCasesWithPrediction(
    xSelectedOrganisationId: string,
    berthIdentifier: string,
    startTime: string,
    numberOfDays?: number
  ): CancelablePromise<Array<BerthDmaCaseSafetyPrediction>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/dma-cases/prediction',
      path: {
        berthIdentifier: berthIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        startTime: startTime,
        numberOfDays: numberOfDays,
      },
    });
  }

  /**
   * Get line force safety predictions
   * Retrieve the safety predictions for line forces for the selected DMA case, loading condition and disposition.
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @param dmaCaseId
   * @param loadingCondition
   * @param disposition
   * @param slotSize The slot size in hours.
   * @param days The number of days to look ahead. 1 by default.
   * @param dateTime
   * @param selectedBandwidth The selected bandwidth.
   * @returns SafetyPredictionsResponse OK
   * @throws ApiError
   */
  public static predictLineForce(
    xSelectedOrganisationId: string,
    berthIdentifier: string,
    dmaCaseId: string,
    loadingCondition: LoadingCondition,
    disposition: VesselDisposition,
    slotSize?: number,
    days?: number,
    dateTime?: string,
    selectedBandwidth?: BandwidthOption
  ): CancelablePromise<SafetyPredictionsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/line-force',
      path: {
        berthIdentifier: berthIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        dmaCaseId: dmaCaseId,
        loadingCondition: loadingCondition,
        disposition: disposition,
        slotSize: slotSize,
        days: days,
        dateTime: dateTime,
        selectedBandwidth: selectedBandwidth,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get fender deflection safety predictions
   * Retrieve the safety predictions for fender deflections for the selected DMA case, loading condition and disposition.
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @param dmaCaseId
   * @param loadingCondition
   * @param disposition
   * @param slotSize The slot size in hours.
   * @param days The number of days to look ahead. 1 by default.
   * @param dateTime
   * @param selectedBandwidth The selected bandwidth.
   * @returns SafetyPredictionsResponse OK
   * @throws ApiError
   */
  public static predictFenderDeflection(
    xSelectedOrganisationId: string,
    berthIdentifier: string,
    dmaCaseId: string,
    loadingCondition: LoadingCondition,
    disposition: VesselDisposition,
    slotSize?: number,
    days?: number,
    dateTime?: string,
    selectedBandwidth?: BandwidthOption
  ): CancelablePromise<SafetyPredictionsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/fender-deflection',
      path: {
        berthIdentifier: berthIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        dmaCaseId: dmaCaseId,
        loadingCondition: loadingCondition,
        disposition: disposition,
        slotSize: slotSize,
        days: days,
        dateTime: dateTime,
        selectedBandwidth: selectedBandwidth,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get motion safety predictions
   * Retrieve the safety predictions for motions for the selected DMA case, loading condition and disposition.
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @param motionType
   * @param dmaCaseId
   * @param loadingCondition
   * @param disposition
   * @param slotSize The slot size in hours.
   * @param days The number of days to look ahead. 1 by default.
   * @param dateTime
   * @param selectedBandwidth The selected bandwidth.
   * @returns SafetyPredictionsResponse OK
   * @throws ApiError
   */
  public static predictMotion(
    xSelectedOrganisationId: string,
    berthIdentifier: string,
    motionType: MotionType,
    dmaCaseId: string,
    loadingCondition: LoadingCondition,
    disposition: VesselDisposition,
    slotSize?: number,
    days?: number,
    dateTime?: string,
    selectedBandwidth?: BandwidthOption
  ): CancelablePromise<SafetyPredictionsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/motion/{motionType}',
      path: {
        berthIdentifier: berthIdentifier,
        motionType: motionType,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        dmaCaseId: dmaCaseId,
        loadingCondition: loadingCondition,
        disposition: disposition,
        slotSize: slotSize,
        days: days,
        dateTime: dateTime,
        selectedBandwidth: selectedBandwidth,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get bollard forces safety predictions
   * Retrieve the safety predictions for bollard forces for the current berth and selected DMA case, loading condition and disposition.
   * Note this is not organisation dependent, since the bollard forces are registered with the berth, not the berth assignment
   *
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @param dmaCaseId
   * @param loadingCondition
   * @param disposition
   * @param slotSize The slot size in hours.
   * @param days The number of days to look ahead. 1 by default.
   * @param dateTime
   * @param selectedBandwidth The selected bandwidth.
   * @returns SafetyPredictionsResponse OK
   * @throws ApiError
   */
  public static predictBollardForces(
    xSelectedOrganisationId: string,
    berthIdentifier: string,
    dmaCaseId: string,
    loadingCondition: LoadingCondition,
    disposition: VesselDisposition,
    slotSize?: number,
    days?: number,
    dateTime?: string,
    selectedBandwidth?: BandwidthOption
  ): CancelablePromise<SafetyPredictionsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/bollard-forces',
      path: {
        berthIdentifier: berthIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        dmaCaseId: dmaCaseId,
        loadingCondition: loadingCondition,
        disposition: disposition,
        slotSize: slotSize,
        days: days,
        dateTime: dateTime,
        selectedBandwidth: selectedBandwidth,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get moored vessels
   * Retrieve the currently moored vessels. That means all vessels that do not have a departure time (yet).
   * @param xSelectedOrganisationId
   * @param dateTime
   * @returns MooredVessel OK
   * @throws ApiError
   */
  public static getMooredVessels(xSelectedOrganisationId: string, dateTime?: string): CancelablePromise<Array<MooredVessel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/moored-vessels',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        dateTime: dateTime,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get moored vessels for berth
   * Retrieve the currently moored vessels for a specific berth. That means all vessels that do not have a departure time (yet).
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @param dateTime
   * @returns MooredVessel OK
   * @throws ApiError
   */
  public static getMooredVesselsForBerth(
    xSelectedOrganisationId: string,
    berthIdentifier: string,
    dateTime?: string
  ): CancelablePromise<Array<MooredVessel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/moored-vessels',
      path: {
        berthIdentifier: berthIdentifier,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        dateTime: dateTime,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get moored vessel for berth
   * Retrieve the currently moored vessel for a specific berth. That means all vessels that do not have a departure time (yet).
   * @param xSelectedOrganisationId
   * @param berthIdentifier
   * @param mmsi
   * @param dateTime
   * @returns MooredVessel OK
   * @throws ApiError
   */
  public static getMooredVesselForBerth(
    xSelectedOrganisationId: string,
    berthIdentifier: string,
    mmsi: string,
    dateTime?: string
  ): CancelablePromise<MooredVessel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/safety-prediction/berths/{berthIdentifier}/moored-vessels/{mmsi}',
      path: {
        berthIdentifier: berthIdentifier,
        mmsi: mmsi,
      },
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        dateTime: dateTime,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }
}
