import { useSearchParams } from 'react-router-dom';

function useSearchParamState<TValue>(name: string, defaultValue: TValue): [TValue, (value: TValue) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = get();

  function get() {
    const searchParamValue = searchParams.get(name);
    if (typeof defaultValue === 'string' && searchParamValue) {
      return (decodeURIComponent(searchParamValue) as TValue) ?? defaultValue;
    }

    return (searchParamValue as TValue) ?? defaultValue;
  }

  function set(newValue: TValue) {
    let encodedNewValue;
    if (typeof newValue === 'string') {
      encodedNewValue = encodeURIComponent(newValue);
    } else {
      encodedNewValue = newValue;
    }
    const params = Object.fromEntries([...searchParams, [name, encodedNewValue]]);

    if (newValue == defaultValue) {
      delete params[name];
    }

    setSearchParams(() => params);
  }

  return [value, set];
}

export default useSearchParamState;
