import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import MainContent from '@/components/layout/MainContent/MainContent';
import Footer from '@/parts/Footer/Footer';
import { defaultMenuGroups } from '@/parts/Sidebar/DefaultSideBarMenu';
import TopNavigationBar from '@/parts/TopNavigationBar/TopNavigationBar';

function DefaultLayout() {
  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Grid item xs={12}>
          <TopNavigationBar menuGroups={defaultMenuGroups} />
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <MainContent>
            <Outlet />
          </MainContent>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default DefaultLayout;
