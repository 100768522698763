/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { BackgroundTaskResponse } from '../models/BackgroundTaskResponse';
import type { DmaCase } from '../models/DmaCase';
import type { DmaCaseDefinitions } from '../models/DmaCaseDefinitions';
import type { DmaCaseMatrixUploadStatusResponse } from '../models/DmaCaseMatrixUploadStatusResponse';
import type { DmaCaseMooringConfigurationUploadResponse } from '../models/DmaCaseMooringConfigurationUploadResponse';
import type { DmaCaseVerificationResponse } from '../models/DmaCaseVerificationResponse';

export class DmaManagementService {
  /**
   * List of DMA case metadata
   * Get the full list of dma-cases and their meta data. This means all dma cases that have been uploaded to Smart Mooring.
   * @returns DmaCase OK
   * @throws ApiError
   */
  public static listDmaCases(): CancelablePromise<Array<DmaCase>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dma-cases',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Upload a DMA case
   * Upload a DMA matrix and the corresponding DMA meta data (for the base of simulations i.e.).
   * When uploading a matrix, the old one will be removed! There is no versioning build in, so only way back would be upload the old matrix again!!!
   *
   * @param formData
   * @returns BackgroundTaskResponse Upload started
   * @throws ApiError
   */
  public static uploadDmaCase(formData?: { file: Array<Blob> }): CancelablePromise<BackgroundTaskResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dma-cases',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Get upload status for dma case
   * Find out whether or not a DMA case upload has completed and if it was successful.
   * @param taskId the ID of the task to find the status for, when none specified, return the whole queue
   * @returns DmaCaseMatrixUploadStatusResponse OK
   * @throws ApiError
   */
  public static getDmaCaseUploadStatus(taskId?: string): CancelablePromise<Array<DmaCaseMatrixUploadStatusResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dma-cases/status',
      query: {
        taskId: taskId,
      },
    });
  }

  /**
   * Upload mooring configuration image
   * Upload the DMA matrix mooring configuration image
   * @param dmaCaseId The DMA case id of the matrix this image belongs to
   * @param formData
   * @returns DmaCaseMooringConfigurationUploadResponse Uploaded
   * @throws ApiError
   */
  public static uploadDmaCaseMooringConfiguration(
    dmaCaseId: string,
    formData?: {
      /**
       * Base64 encoded image
       */
      file: Blob;
    }
  ): CancelablePromise<DmaCaseMooringConfigurationUploadResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dma-cases/{dmaCaseId}/mooring-configuration',
      path: {
        dmaCaseId: dmaCaseId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Validate the DMA case metadata
   * Validate the DMA case metadata
   * @param formData
   * @returns any Valid
   * @throws ApiError
   */
  public static validateDmaCaseMetadata(formData?: { file: Blob }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dma-cases/metadata/validate',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `JSON contains an error`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Verify the DMA case matrices
   * Return a list of discrepancies between the DMA case matrices and the supplied metadata
   * @returns DmaCaseVerificationResponse OK
   * @throws ApiError
   */
  public static verifyDmaCases(): CancelablePromise<Array<DmaCaseVerificationResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dma-cases/verify',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Retrieve the library DMA case definitions
   * Get the full list of dma-case definitions in the library. Can be used to assign/purchase dma cases to berths / organisations.
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static listLibraryDmaCaseDefinitions(): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dma-cases/library',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Retrieve the specific DMA case definitions
   * Get the full list of specific dma-case definitions. Can be used to assign/purchase dma cases to berths / organisations.
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static listSpecificDmaCaseDefinitions(): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dma-cases/specific',
      errors: {
        500: `Unexpected error`,
      },
    });
  }
}
