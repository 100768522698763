import { AccountCircle, HomeRounded } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import { Alert, Box, Button, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ApiError } from '@/api/ui/requests';
import { formatWithLineBreaks } from '@/utils/textUtils';

export interface ApiErrorAlertProps {
  error: unknown;
}

function ApiErrorAlert({ error }: ApiErrorAlertProps) {
  const err = error as ApiError;
  const { logout } = useAuth0();
  const navigate = useNavigate();

  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError;
    if (axiosError.code === 'ERR_NETWORK') {
      return (
        <Alert severity="error">
          Currently Smart Mooring is unavailable, please try again in a couple of minutes, if the problem continues, do not hesitate to contact the
          Smart Mooring support team!
        </Alert>
      );
    }
    return <Alert severity="error">{formatWithLineBreaks(axiosError.message)}</Alert>;
  } else if (err.status === 401 || err.status === 403) {
    return (
      <Box key="api-error" sx={{ marginX: 'auto', marginY: 20, width: { xs: '75vw', md: '60wv', lg: '45vw' } }}>
        <Alert severity="error">
          <Grid container direction={'row'} alignItems={'center'}>
            <Grid item xs={12} role={'alert'}>
              {err.message}.
            </Grid>
            <Grid item xs={12} marginTop={4}>
              <Grid container direction={'row'} alignItems={'center'} rowSpacing={2}>
                <Grid item xs={12} sm={7} lg={6}>
                  <Button
                    startIcon={<HomeRounded />}
                    color={'primary'}
                    variant={'contained'}
                    title={'Logout'}
                    onClick={() => navigate('/safety-prediction')}
                    sx={{ marginRight: 2 }}
                  >
                    Home
                  </Button>
                  <Button
                    startIcon={<Logout />}
                    color={'primary'}
                    variant={'contained'}
                    title={'Logout'}
                    onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                  >
                    Logout
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5} lg={6}>
                  <Button
                    startIcon={<AccountCircle />}
                    sx={{ marginLeft: 6 }}
                    color={'primary'}
                    variant={'contained'}
                    title={'Smart Society account portal'}
                    href={'https://account.smart-society.io/account-settings'}
                  >
                    Smart Society
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Alert>
      </Box>
    );
  } else {
    const apiError = error as { body: { message: string } };
    const errorMessage = apiError?.body?.message ?? err.message;

    return <Alert severity="error">{formatWithLineBreaks(errorMessage)}</Alert>;
  }
}

export default ApiErrorAlert;
