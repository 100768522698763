/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { MetoceanForecast } from '../models/MetoceanForecast';
import type { VesselClearanceForecast } from '../models/VesselClearanceForecast';

export class MetoceanService {
  /**
   * Get wind forecasts
   * Get the weather forecasts for a berth and a certain number of days to be displayed in the vessel clearance module.
   * @param xSelectedOrganisationId
   * @param berthId
   * @param bollardThresholdSurpassedForBeaufort
   * @param bollardSwlSurpassedForBeaufort
   * @param days
   * @param dateTime
   * @returns VesselClearanceForecast OK
   * @throws ApiError
   */
  public static getVesselClearanceForecasts(
    xSelectedOrganisationId: string,
    berthId: string,
    bollardThresholdSurpassedForBeaufort: number,
    bollardSwlSurpassedForBeaufort: number,
    days?: number,
    dateTime?: string
  ): CancelablePromise<VesselClearanceForecast> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metocean/vessel-clearance/forecasts',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        berthId: berthId,
        bollardThresholdSurpassedForBeaufort: bollardThresholdSurpassedForBeaufort,
        bollardSwlSurpassedForBeaufort: bollardSwlSurpassedForBeaufort,
        days: days,
        dateTime: dateTime,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }

  /**
   * Get metocean forecasts
   * Get the metocean forecasts for a berth and a certain number of days.
   * @param xSelectedOrganisationId
   * @param berthId
   * @param days
   * @returns MetoceanForecast OK
   * @throws ApiError
   */
  public static getForecasts(xSelectedOrganisationId: string, berthId: string, days?: number): CancelablePromise<Array<MetoceanForecast>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metocean/forecasts',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        berthId: berthId,
        days: days,
      },
      errors: {
        400: `An error occurred caused by invalid content sent to our application.`,
        401: `Not authorized to see this content`,
        500: `Some unexpected error occurred, probably caused by either a configuration error or a programming mistake. Please contact the development team!`,
      },
    });
  }
}
