import { useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment/moment';
import {
  useBehindTheScenesServiceSelectMatrixRow,
  useOrganisationManagementServiceGetBerthAssignments,
  useOrganisationManagementServiceListOrganisations,
} from '@/api/management/queries';
import { ApiError, BerthAssignment, LoadingCondition, MatrixRowSelectionResponse, Organisation, VesselDisposition } from '@/api/management/requests';
import Select from '@/components/common/Select/Select';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import MatrixRowSelectionView, { humanise } from '@/features/Admin/MatrixRowFinder/MatrixRowSelectionView';
import usePageTitle from '@/hooks/usePageTitle';

interface MatrixRowSelectionForm {
  organisation: Organisation | null;
  berthAssignment: BerthAssignment | null;
  dmaCaseId: string | null;
  loadingCondition: LoadingCondition | null;
  disposition: VesselDisposition | null;
  timestamp: Moment | null;
}

export type MatrixRowSelection = {
  loading: boolean;
  errorMessage: string | null;
  result: MatrixRowSelectionResponse | null;
};

function MatrixRowFinder() {
  usePageTitle('Matrix row finder');

  const [formValues, setFormValues] = useState<MatrixRowSelectionForm>({
    organisation: null,
    berthAssignment: null,
    dmaCaseId: null,
    loadingCondition: LoadingCondition.LOADED,
    disposition: VesselDisposition.PORTSIDE,
    timestamp: moment().local(),
  });

  const { data: organisations } = useOrganisationManagementServiceListOrganisations();

  const berthAssignmentRequest = { organisationId: formValues.organisation?.organisationId ?? '' };
  const { data: berthAssignments } = useOrganisationManagementServiceGetBerthAssignments(berthAssignmentRequest, undefined, {
    enabled: berthAssignmentRequest.organisationId != '',
  });

  const matrixRowSelectionRequest = {
    organisationId: formValues.organisation?.organisationId ?? '',
    berthId: formValues.berthAssignment?.berthId ?? '',
    dmaCaseId: formValues.dmaCaseId ?? '',
    loadingCondition: formValues.loadingCondition ?? LoadingCondition.LOADED,
    disposition: formValues.disposition ?? VesselDisposition.PORTSIDE,
    timestamp: (formValues.timestamp ?? moment()).format(),
  };
  const [matrixRowSelection, setMatrixRowSelection] = useState<MatrixRowSelection>({ loading: false, errorMessage: null, result: null });
  const selection = useBehindTheScenesServiceSelectMatrixRow({
    onError: (error) => {
      console.log(error);
      setMatrixRowSelection({
        loading: false,
        errorMessage: (error as ApiError).body.message ?? "Can't select DMA case matrix row. Check the console for details.",
        result: null,
      });
    },
    onSuccess: (response) => setMatrixRowSelection({ loading: false, errorMessage: null, result: response }),
  });
  if (organisations) {
    return (
      <Grid container columnSpacing={4}>
        <Grid item xs={12}>
          <Typography variant={'body1'} component={'span'}>
            Select the organisation, berth and DMA case you want to verify. The time you select is the time of your browsers timezone!
          </Typography>
        </Grid>
        <Grid container item rowSpacing={2} xs={4} alignContent="flex-start">
          <Grid item xs={12}>
            <h3>Criteria</h3>
          </Grid>

          <Grid item xs={12}>
            <Select<Organisation, Organisation | null>
              label="Organisation"
              options={organisations}
              value={formValues.organisation}
              displayFunction={(organisation) => organisation.name}
              onChange={(organisation) => setFormValues({ ...formValues, organisation, berthAssignment: null, dmaCaseId: null })}
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Select<BerthAssignment, BerthAssignment | null>
              label="Berth"
              options={berthAssignments?.filter((assignment) => assignment?.dmaCases.length > 0) || []}
              value={formValues.berthAssignment}
              displayFunction={(berthAssignment) => `${berthAssignment.berthId} - ${berthAssignment.name}`}
              onChange={(berthAssignment) => setFormValues({ ...formValues, berthAssignment, dmaCaseId: null })}
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Select<string, string | null>
              label="DMA case ID"
              options={formValues.berthAssignment?.dmaCases || []}
              value={formValues.dmaCaseId}
              onChange={(dmaCaseId) => setFormValues({ ...formValues, dmaCaseId })}
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Select<LoadingCondition, LoadingCondition | null>
              label="Loading condition"
              options={Object.values(LoadingCondition)}
              value={formValues.loadingCondition}
              displayFunction={(loadingCondition: LoadingCondition) => humanise(loadingCondition)}
              onChange={(loadingCondition: LoadingCondition | null) => setFormValues({ ...formValues, loadingCondition })}
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Select<VesselDisposition, VesselDisposition | null>
              label="Disposition"
              options={Object.values(VesselDisposition)}
              value={formValues.disposition}
              displayFunction={(disposition: VesselDisposition) => humanise(disposition)}
              onChange={(disposition: VesselDisposition | null) => setFormValues({ ...formValues, disposition })}
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Timestamp"
                value={formValues.timestamp.local()}
                onChange={(timestamp: Moment | null) => setFormValues({ ...formValues, timestamp })}
                slotProps={{ textField: { fullWidth: true } }}
                format={'DD-MM-YYYY HH:mm'}
                ampm={false}
                ampmInClock={false}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              ></DateTimePicker>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  setMatrixRowSelection({ loading: true, errorMessage: null, result: null });

                  return selection.mutateAsync({ requestBody: matrixRowSelectionRequest });
                }}
                disabled={
                  matrixRowSelectionRequest.organisationId == '' ||
                  matrixRowSelectionRequest.berthId == '' ||
                  matrixRowSelectionRequest.dmaCaseId == '' ||
                  !matrixRowSelectionRequest.loadingCondition ||
                  !matrixRowSelectionRequest.disposition ||
                  !matrixRowSelectionRequest.timestamp ||
                  matrixRowSelection.loading
                }
              >
                Select row
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <MatrixRowSelectionView
            loading={matrixRowSelection.loading}
            errorMessage={matrixRowSelection.errorMessage}
            result={matrixRowSelection.result}
          />
        </Grid>
      </Grid>
    );
  }

  return <LoadingIndicator message={'Loading organisations'} />;
}

export default MatrixRowFinder;
