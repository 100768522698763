import { List, ListItem } from '@mui/material';
import Select from '@/components/common/Select/Select';
import useOrganisation from '@/hooks/useOrganisation';

function AppMenuOrganisationSwitch() {
  const { selectedOrganisationId, switchOrganisation, allowedOrganisations } = useOrganisation();

  return allowedOrganisations?.length >= 2 ? (
    <List>
      <ListItem>
        <Select
          name="organisationId"
          label="Organisation"
          options={allowedOrganisations}
          value={selectedOrganisationId}
          displayFunction={(value) => value?.organisationName ?? ''}
          valueFunction={(value) => value?.organisationId ?? ''}
          onChange={switchOrganisation}
          required={true}
          fullWidth={true}
          placeHolder="Select an option"
        ></Select>
      </ListItem>
    </List>
  ) : (
    <></>
  );
}

export default AppMenuOrganisationSwitch;
