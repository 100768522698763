import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Berth } from '@/api/management/requests';
import Select from '@/components/common/Select/Select';
import { enqueueErrorSnackbar } from '@/components/management/EnqueueErrorSnackbar/EnqueueErrorSnackbar';

interface UpdateBerthDialogProps<TData, TError, TVariables, TContext> {
  open: boolean;
  berth: Berth | undefined;
  mutation: UseMutationResult<TData, TError, TVariables, TContext>;
  getMutationValues: (berth: Berth) => TVariables;
  closeDialog: (disabled: boolean, newBerth: Berth) => void;
}

function EditBerthDialog<TData, TError, TVariables, TContext>({
  open,
  berth,
  mutation,
  getMutationValues,
  closeDialog,
}: UpdateBerthDialogProps<TData, TError, TVariables, TContext>) {
  const [newBerth, setNewBerth] = useState(berth);
  const snackbarContext = useSnackbar();

  useEffect(() => {
    setNewBerth(berth);
  }, [berth]);

  const modifyBerth = async () => {
    if (berth) {
      const values = getMutationValues(newBerth);
      await mutation
        .mutateAsync(values)
        .then(() => closeDialog(true, newBerth))
        .catch((error) => {
          closeDialog(false, berth);
          enqueueErrorSnackbar(snackbarContext, `Could not update berth: ${error.body?.message}`, 'toggle-berth-mooring-detection-error');
        });
    } else {
      closeDialog(false, berth);
    }
  };
  return (
    <Dialog open={open} onClose={() => closeDialog(false, berth)}>
      <DialogTitle>Modify Berth</DialogTitle>
      <DialogContent sx={{ '& > :not(style)': { m: 1 } }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Berth ID"
                value={newBerth?.berthId}
                onChange={(e) => setNewBerth({ ...newBerth, berthId: e.target.value })}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Name" value={newBerth?.name} onChange={(e) => setNewBerth({ ...newBerth, name: e.target.value })} />
            </Grid>
            <Grid item xs={5}>
              <Select
                options={['Quay', 'Jetty', 'Buoy', 'FSRU']}
                label="Type"
                value={newBerth?.type}
                fullWidth={true}
                onChange={(e) => setNewBerth({ ...newBerth, type: e })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Latitude"
                value={newBerth?.location?.latitude}
                onChange={(e) =>
                  setNewBerth({
                    ...newBerth,
                    location: { ...newBerth.location, latitude: parseFloat(e.target.value) },
                  })
                }
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Longitude"
                value={newBerth?.location?.longitude}
                onChange={(e) =>
                  setNewBerth({
                    ...newBerth,
                    location: { ...newBerth.location, longitude: parseFloat(e.target.value) },
                  })
                }
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Bollard Capacity"
                value={newBerth?.bollardInformation?.capacity}
                onChange={(e) =>
                  setNewBerth({
                    ...newBerth,
                    bollardInformation: { ...newBerth?.bollardInformation, capacity: parseInt(e.target.value) },
                  })
                }
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Bollard Height"
                value={newBerth?.bollardInformation?.height}
                onChange={(e) =>
                  setNewBerth({
                    ...newBerth,
                    bollardInformation: { ...newBerth?.bollardInformation, height: parseFloat(e.target.value) },
                  })
                }
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Orientation"
                value={newBerth?.orientation}
                onChange={(e) => setNewBerth({ ...newBerth, orientation: parseInt(e.target.value) })}
                type="number"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => closeDialog(false, berth)} data-testid="cancel-modify-berth-button">
          Cancel
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          color="success"
          loadingPosition="end"
          endIcon={<CheckCircleIcon />}
          onClick={modifyBerth}
          disabled={!berth}
          data-testid="modify-berth-button"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default EditBerthDialog;
