import { ReactElement, createContext } from 'react';
import { AllowedOrganisation, OrganisationSummary } from '@/api/ui/requests';

export interface SmartMooringContextInterface {
  switchOrganisation: (orgId: string) => void;
  selectedOrganisationId: string | undefined;
  selectedOrganisation: OrganisationSummary | undefined;
  allowedOrganisations: Array<AllowedOrganisation>;
  pageTitle: string;
  pageTitleIcon: ReactElement | undefined;
  setPageTitle: (title: string) => void;
  setPageTitleIcon: (element: ReactElement | undefined) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <SmartMooringProvider>.');
};

export const initialContext: SmartMooringContextInterface = {
  allowedOrganisations: [],
  selectedOrganisationId: undefined,
  switchOrganisation: stub,
  selectedOrganisation: undefined,
  pageTitle: document.title,
  pageTitleIcon: undefined,
  setPageTitle: stub,
  setPageTitleIcon: stub,
};

const SmartMooringContext = createContext<SmartMooringContextInterface>(initialContext);

export default SmartMooringContext;
