/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { AssignUserRequest } from '../models/AssignUserRequest';
import type { BerthAssignment } from '../models/BerthAssignment';
import type { BerthDmaCaseAssignmentResponse } from '../models/BerthDmaCaseAssignmentResponse';
import type { BerthDmaCasesAssignmentRequest } from '../models/BerthDmaCasesAssignmentRequest';
import type { BerthUpdateRequest } from '../models/BerthUpdateRequest';
import type { BulkWindAmplificationFactorsPerDmaCasesUpdateRequest } from '../models/BulkWindAmplificationFactorsPerDmaCasesUpdateRequest';
import type { DmaCaseDefinitions } from '../models/DmaCaseDefinitions';
import type { MetoceanStationAssignmentRequest } from '../models/MetoceanStationAssignmentRequest';
import type { Organisation } from '../models/Organisation';
import type { OrganisationLogoResponse } from '../models/OrganisationLogoResponse';
import type { OrganisationRequest } from '../models/OrganisationRequest';
import type { OrganisationResponse } from '../models/OrganisationResponse';
import type { OrganisationSearchRequest } from '../models/OrganisationSearchRequest';
import type { Person } from '../models/Person';
import type { ToggleDmaCases } from '../models/ToggleDmaCases';
import type { WindAmplificationFactorUpdateRequest } from '../models/WindAmplificationFactorUpdateRequest';
import type { WindAmplificationFactorsPerDmaCaseUpdateRequest } from '../models/WindAmplificationFactorsPerDmaCaseUpdateRequest';

export class OrganisationManagementService {
  /**
   * List organisations
   * Retrieve all organisations in Smart Mooring. Including configured berths, dma cases, people etc.
   * @returns Organisation OK
   * @throws ApiError
   */
  public static listOrganisations(): CancelablePromise<Array<Organisation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations',
    });
  }

  /**
   * Add/Update an organisation
   * @param requestBody Add/Update a new organisation, i.e. rename the organisation, or add a new organisation.
   * @returns OrganisationResponse OK
   * @throws ApiError
   */
  public static saveOrganisation(requestBody: OrganisationRequest): CancelablePromise<OrganisationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Find organisation
   * Retrieve an organisation using the ID, which corresponds to the Smart Society organisation ID.
   * @param organisationId the (Smart Society) ID of the organisation
   * @returns Organisation OK
   * @throws ApiError
   */
  public static getOrganisation(organisationId: string): CancelablePromise<Organisation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations/{organisationId}',
      path: {
        organisationId: organisationId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete organisation logo
   * Delete the organisation logo
   * @param organisationId The ID of the organisation
   * @returns void
   * @throws ApiError
   */
  public static deleteOrganisationLogo(organisationId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organisations/{organisationId}/logo',
      path: {
        organisationId: organisationId,
      },
      errors: {
        304: `Nothing has changed. The logo was not found.`,
        500: `Error while deleting organisation logo`,
      },
    });
  }

  /**
   * Upload organisation logo
   * Upload the organisation logo
   * @param organisationId The ID of the organisation
   * @param formData
   * @returns OrganisationLogoResponse Logo uploaded
   * @throws ApiError
   */
  public static uploadOrganisationLogo(
    organisationId: string,
    formData?: {
      /**
       * Base64 encoded organisation logo
       */
      file?: Blob;
    }
  ): CancelablePromise<OrganisationLogoResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/logo',
      path: {
        organisationId: organisationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        500: `Error while uploading organisation logo`,
      },
    });
  }

  /**
   * Synchronise Smart Society license
   * Synchronise the Smart Society organisation using the ID, which corresponds to the Smart Society organisation ID
   * and update license information from Smart Society. When no organisationId is supplied, all organisations will be synchronised.
   *
   * @param organisationId the (Smart Society) ID of the organisation
   * @returns void
   * @throws ApiError
   */
  public static synchroniseSmartSocietyLicense(organisationId?: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations/smart-society/license',
      query: {
        organisationId: organisationId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * List assigned users
   * Retrieve the list of users that are assigned to this organisation.
   * @param organisationId the (Smart Society) ID of the organisation
   * @returns Person OK
   * @throws ApiError
   */
  public static listOrganisationUsers(organisationId: string): CancelablePromise<Array<Person>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations/{organisationId}/users',
      path: {
        organisationId: organisationId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Assign user
   * Assign a user to this organisation. The user will be added to the Smart Society organisation as well.
   * @param organisationId the ID of the organisation
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static assignUser(organisationId: string, requestBody: AssignUserRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/users',
      path: {
        organisationId: organisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Cannot assign user to organisation, for instance when the email address cannot be found in Smart Society`,
        404: `Organisation not Found`,
      },
    });
  }

  /**
   * Unassign user
   * Unassign the user from this organisation. Once the user is logged out, it will no longer have access to this organisation.
   * @param organisationId the ID of the organisation
   * @param personId the ID of the person as known in Smart Society
   * @returns void
   * @throws ApiError
   */
  public static unassignUser(organisationId: string, personId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organisations/{organisationId}/users',
      path: {
        organisationId: organisationId,
      },
      query: {
        personId: personId,
      },
      errors: {
        404: `Organisation not Found`,
      },
    });
  }

  /**
   * Find organisations
   * Retrieve a list of organisations matching the criteria
   * @param requestBody The criteria to find organisations by.
   * @returns Organisation OK
   * @throws ApiError
   */
  public static findOrganisations(requestBody: OrganisationSearchRequest): CancelablePromise<Array<Organisation>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/search',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Purchase DMA case(s)
   * Purchase DMA case(s). The supplied list will be added to the list of purchased DMA cases for this organisation.
   * Any duplicates will be filtered out.
   *
   * @param organisationId The ID of the organisation
   * @param requestBody The DMA cases to evaluate
   * @returns any OK
   * @throws ApiError
   */
  public static purchaseDmaCases(organisationId: string, requestBody: ToggleDmaCases): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/dma-cases',
      path: {
        organisationId: organisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List purchased DMA cases
   * Retrieve the DMA cases that have been purchased by this organisation
   * @param organisationId The ID of the organisation
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static listPurchasedDmaCases(organisationId: string): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations/{organisationId}/dma-cases',
      path: {
        organisationId: organisationId,
      },
    });
  }

  /**
   * Evaluate DMA case(s)
   * Evaluate DMA case(s) for an organisation
   * @param organisationId The ID of the organisation
   * @param requestBody The DMA cases to evaluate
   * @returns any OK
   * @throws ApiError
   */
  public static evaluateDmaCases(organisationId: string, requestBody: ToggleDmaCases): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/evaluation-dma-cases',
      path: {
        organisationId: organisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List evaluation DMA cases
   * Retrieve the DMA cases that are being evaluated by this organisation
   * @param organisationId The ID of the organisation
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static listEvaluationDmaCases(organisationId: string): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations/{organisationId}/evaluation-dma-cases',
      path: {
        organisationId: organisationId,
      },
    });
  }

  /**
   * Promote organisation
   * Promote organisation to production when it is currently a trial organisation
   * @param organisationId The ID of the organisation to promote
   * @returns any OK
   * @throws ApiError
   */
  public static promoteOrganisationToProduction(organisationId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/promotion',
      path: {
        organisationId: organisationId,
      },
    });
  }

  /**
   * Link parent organisaton
   * Link organisation to a parent organisation to create a hierarchy
   * @param parentOrganisationId The ID of the parent organisation
   * @param organisationId The ID of the child organisation
   * @param licenseId The ID of the license from Smart Society to use for the child organisation
   * @returns any OK
   * @throws ApiError
   */
  public static linkOrganisations(parentOrganisationId: string, organisationId: string, licenseId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{parentOrganisationId}/link/{organisationId}',
      path: {
        parentOrganisationId: parentOrganisationId,
        organisationId: organisationId,
      },
      query: {
        licenseId: licenseId,
      },
    });
  }

  /**
   * Import organisations
   * Import multiple organisations in bulk, mostly used when setting up the environment.
   * @param requestBody The organisations to import.
   * @returns OrganisationResponse OK
   * @throws ApiError
   */
  public static importOrganisations(requestBody: Array<OrganisationRequest>): CancelablePromise<Array<OrganisationResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/import',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get the assigned berths
   * Get all assigned berths and their configuration (dma cases, which metocean station etc.)
   * @param organisationId The ID of the organisation to get the berth assignments from
   * @returns BerthAssignment OK
   * @throws ApiError
   */
  public static getBerthAssignments(organisationId: string): CancelablePromise<Array<BerthAssignment>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations/{organisationId}/berths',
      path: {
        organisationId: organisationId,
      },
    });
  }

  /**
   * Update assigned berths
   * Assign berths to this organisation. Note that all berths that need to be assigned have to be supplied in the request body,
   * also the already assigned berths.
   *
   * @param organisationId The ID of the organisation to configure
   * @param requestBody Array of berths to assign to the organisation
   * @returns string OK
   * @throws ApiError
   */
  public static updateBerthAssignments(organisationId: string, requestBody: BerthUpdateRequest): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths',
      path: {
        organisationId: organisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Enable berth assignments
   * Enable the metocean updates for all berths assigned to this organisation.
   * @param organisationId
   * @returns string OK
   * @throws ApiError
   */
  public static enableBerthAssignments(organisationId: string): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/enable',
      path: {
        organisationId: organisationId,
      },
    });
  }

  /**
   * Disable berth assignments
   * Disable the metocean updates for all berths assigned to this organisation.
   *
   * @param organisationId
   * @returns string OK
   * @throws ApiError
   */
  public static disableBerthAssignments(organisationId: string): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/disable',
      path: {
        organisationId: organisationId,
      },
    });
  }

  /**
   * Assign DMA case(s) (bulk)
   * Assign DMA case(s) to multiple berths as specified in the request body
   * @param organisationId The ID of the organisation to configure
   * @param requestBody Array of berth dma case assignments
   * @returns BerthDmaCaseAssignmentResponse OK
   * @throws ApiError
   */
  public static assignBulkDmaCases(
    organisationId: string,
    requestBody: Array<BerthDmaCasesAssignmentRequest>
  ): CancelablePromise<Array<BerthDmaCaseAssignmentResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/dma-cases',
      path: {
        organisationId: organisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Get berth assignment
   * Get the berth assignment information (dma cases, metocean station etc.) for this berth
   * @param organisationId The ID of the organisation to get the berth assignments from
   * @param berthId The ID of the berth to which the dma case(s) are added
   * @returns BerthAssignment OK
   * @throws ApiError
   */
  public static getBerthAssignment(organisationId: string, berthId: string): CancelablePromise<BerthAssignment> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisations/{organisationId}/berths/{berthId}',
      path: {
        organisationId: organisationId,
        berthId: berthId,
      },
    });
  }

  /**
   * Enable berth assignment
   * Enable the metocean updates for this berth and also the monitoring fo the berth polygon for moored vessels.
   * @param organisationId
   * @param berthId
   * @returns BerthAssignment OK
   * @throws ApiError
   */
  public static enableBerthAssignment(organisationId: string, berthId: string): CancelablePromise<BerthAssignment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/{berthId}',
      path: {
        organisationId: organisationId,
        berthId: berthId,
      },
    });
  }

  /**
   * Disable the berth assignment
   * Disable the metocean updates for this berth and also the monitoring fo the berth polygon for moored vessels.
   *
   * @param organisationId
   * @param berthId
   * @returns any OK
   * @throws ApiError
   */
  public static disableBerthAssignment(organisationId: string, berthId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organisations/{organisationId}/berths/{berthId}',
      path: {
        organisationId: organisationId,
        berthId: berthId,
      },
    });
  }

  /**
   * Assign metocean station to this berth
   * Enable, update or change metocean station for this berth
   * @param organisationId
   * @param berthId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static assignMetoceanStation(
    organisationId: string,
    berthId: string,
    requestBody?: MetoceanStationAssignmentRequest
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/{berthId}/metocean-station',
      path: {
        organisationId: organisationId,
        berthId: berthId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Disable the metocean station
   * Disable the metocean updates for this berth and also the monitoring fo the berth polygon for moored vessels.
   *
   * @param organisationId
   * @param berthId
   * @returns any OK
   * @throws ApiError
   */
  public static disableMetoceanStation(organisationId: string, berthId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organisations/{organisationId}/berths/{berthId}/metocean-station',
      path: {
        organisationId: organisationId,
        berthId: berthId,
      },
    });
  }

  /**
   * Assign DMA case(s)
   * Assign DMA case(s) to a berth that is assigned to this organisation.
   * Note that all DMA cases that have to be assigned to this berth need to be supplied, also the already assigned ones!
   *
   * @param organisationId The ID of the organisation to configure
   * @param berthId The ID of the berth to which the dma case(s) are added
   * @param requestBody Array of dma case identifiers
   * @returns string OK
   * @throws ApiError
   */
  public static assignDmaCases(organisationId: string, berthId: string, requestBody: Array<string>): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/{berthId}/dma-cases',
      path: {
        organisationId: organisationId,
        berthId: berthId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Upload wind amplification factors for a dmaCase
   * Upload wind amplification factors for a dmaCase that assigned to a berth within a specific organisation.
   *
   * @param berthId
   * @param organisationId
   * @param dmaCaseId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static uploadWindAmplificationFactors(
    berthId: string,
    organisationId: string,
    dmaCaseId: string,
    requestBody: Array<WindAmplificationFactorUpdateRequest>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/{berthId}/dma-cases/{dmaCaseId}/wind-amplification-factors',
      path: {
        berthId: berthId,
        organisationId: organisationId,
        dmaCaseId: dmaCaseId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Upload wind amplification factors for dmaCases within a berth
   * Upload wind amplification factors corresponding to dma cases that are assigned to a berth within a specific organisation.
   *
   * @param berthId
   * @param organisationId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static uploadWindAmplificationFactorsPerDmaCases(
    berthId: string,
    organisationId: string,
    requestBody: Array<WindAmplificationFactorsPerDmaCaseUpdateRequest>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/berths/{berthId}/wind-amplification-factors-per-dma-cases',
      path: {
        berthId: berthId,
        organisationId: organisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param organisationId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static bulkUploadWindAmplificationFactorsPerDmaCases(
    organisationId: string,
    requestBody: Array<BulkWindAmplificationFactorsPerDmaCasesUpdateRequest>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisations/{organisationId}/wind-amplification-factors-per-dma-cases/bulk',
      path: {
        organisationId: organisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
