import { useEffect, useState } from 'react';
import { TableRow } from '@mui/material';
import { Table } from '@tanstack/react-table';
import GridOverviewTableHeaderCell from './GridOverviewTableHeaderCell';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from './types';

interface GridOverviewTableHeaderProps<TRowModel> {
  table: Table<TRowModel>;
}

function GridOverviewTableHeader<TRowModel>({ table }: GridOverviewTableHeaderProps<TRowModel>) {
  const [totalFlex, setTotalFlex] = useState(0);

  // Get the total flex count for the table columns
  // This is used to calculate the percentage width for each column.
  useEffect(() => {
    let totalFlexCount = 0;

    table.getLeafHeaders().forEach((header) => {
      const metadata = header.column.columnDef.meta as GridOverviewColumnMetadata;
      totalFlexCount += metadata?.flex ?? DefaultGridOverviewColumnMetadata.flex;
    });
    setTotalFlex(totalFlexCount);
  }, [table]);

  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            const metadata = (header.column.columnDef.meta as GridOverviewColumnMetadata) ?? DefaultGridOverviewColumnMetadata;
            return (
              <GridOverviewTableHeaderCell
                key={header.column.columnDef.id ?? index}
                table={table}
                header={header}
                metadata={metadata}
                width={(metadata.flex ?? DefaultGridOverviewColumnMetadata.flex) / totalFlex}
              />
            );
          })}
        </TableRow>
      ))}
    </>
  );
}

export default GridOverviewTableHeader;
