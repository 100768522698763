import { forwardRef } from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavLink as NavLinkBase, NavLinkProps } from 'react-router-dom';
import useLicence from '@/hooks/useLicence';
import css from './index.module.css';
import { MenuItemDefinitionProps } from './types';

function AppMenuItem(item: MenuItemDefinitionProps, key: string) {
  const theme = useTheme();
  const { hasRole } = useLicence();
  const { hasFeature } = useLicence();

  // eslint-disable-next-line react/display-name
  const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ ...props }, ref) => {
    return (
      <NavLinkBase ref={ref} {...props} className={props.className} style={({ isActive }) => (isActive ? theme.navLinkActive : {})}>
        {props.children}
      </NavLinkBase>
    );
  });

  const icon = item.icon && (
    <ListItemIcon
      role={'img'}
      title={item.displayText}
      className={css.navLinkIcon}
      sx={{
        minWidth: 0,
        mr: item.collapsed ? 'auto' : 2,
        justifyContent: 'center',
      }}
    >
      {item.icon}
    </ListItemIcon>
  );

  const text = <ListItemText primary={item.displayText} sx={{ opacity: item.collapsed ? 0 : 1 }} />;

  function renderItem() {
    if (item.linkTo) {
      return (
        <ListItemButton
          component={NavLink}
          to={item.linkTo}
          disabled={item.disabled || !hasFeature(item.licenceFeature)}
          sx={{
            justifyContent: item.collapsed ? 'center' : 'initial',
            px: 2.5,
          }}
        >
          {icon}
          {text}
        </ListItemButton>
      );
    }
    if (item.externalLinkTo) {
      return (
        <ListItemButton
          component="a"
          href={item.externalLinkTo}
          target="_blank"
          disabled={item.disabled || !hasFeature(item.licenceFeature)}
          sx={{
            justifyContent: item.collapsed ? 'center' : 'initial',
            px: 2.5,
          }}
        >
          {icon}
          {text}
        </ListItemButton>
      );
    }
    return (
      <ListItemButton
        sx={{
          justifyContent: item.collapsed ? 'center' : 'initial',
          px: 2.5,
        }}
        disabled={item.disabled || !hasFeature(item.licenceFeature)}
        onClick={item.onClick}
      >
        {icon}
        {text}
      </ListItemButton>
    );
  }

  if (!item.allowedRoles || hasRole(item.allowedRoles))
    return (
      <ListItem key={key} disablePadding sx={{ display: 'block' }}>
        <Tooltip title={item.description ? item.description : item.displayText} placement="right" role={'tooltip'} arrow={true}>
          {renderItem()}
        </Tooltip>
      </ListItem>
    );
  else return <></>;
}

export default AppMenuItem;
