/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { AuditTrailResponse } from '../models/AuditTrailResponse';
import type { AuthenticationAuditTrailResponse } from '../models/AuthenticationAuditTrailResponse';
import type { BerthSafetyAuditTrailResponse } from '../models/BerthSafetyAuditTrailResponse';
import type { CommandAuditTrailResponse } from '../models/CommandAuditTrailResponse';
import type { DomainEvent } from '../models/DomainEvent';

export class AuditTrailService {
  /**
   * List events
   * Retrieves a paginated list of domain event summaries.
   * The list can be filtered on the type of object and the ID of the object.
   * For instance to find all events for a berth with ID NLRMT-0004, or all events for a vessel with MMSI 244670000.
   *
   * @param type The type of object to filter on, i.e. Organisation, Berth, Vessel, Visit, MetoceanStation.
   * @param id The ID of the object to filter on, for instance NLRTM-0004, MeteoGroup-3232 or the MMSI of a vessel.
   * For Visit it is a UUID and for Organisation it is the Smart Society organisation id (also a UUID)
   *
   * @param pageNumber The page number to use. 1-based, defaults to 1.
   * @param pageSize The page size to use. 10, 25, 50, 100,....
   * @returns AuditTrailResponse OK
   * @throws ApiError
   */
  public static findEvents(type?: string, id?: string, pageNumber?: number, pageSize?: number): CancelablePromise<AuditTrailResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/audit-trail',
      query: {
        type: type,
        id: id,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
  }

  /**
   * List authentication failures
   * Retrieves a list of authentication faulures
   *
   * @param pageNumber The page number to use. 1-based, defaults to 1.
   * @param pageSize The page size to use. 10, 25, 50, 100,....
   * @returns AuthenticationAuditTrailResponse OK
   * @throws ApiError
   */
  public static listAuthenticationFailures(pageNumber?: number, pageSize?: number): CancelablePromise<AuthenticationAuditTrailResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/audit-trail/authentication',
      query: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
  }

  /**
   * List commands
   * Retrieves a list of commands, usefull when debugging commands that do not result in an event, so you can see what the input was.
   *
   * @param pageNumber The page number to use. 1-based, defaults to 1.
   * @param pageSize The page size to use. 10, 25, 50, 100,....
   * @returns CommandAuditTrailResponse OK
   * @throws ApiError
   */
  public static listCommands(pageNumber?: number, pageSize?: number): CancelablePromise<CommandAuditTrailResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/audit-trail/commands',
      query: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Find an domain event by ID
   * Find a domain event by ID. The ID is the UUID of the event as stored in the aggregate.
   * The domain event contains all details, including the event payload.
   *
   * @param id the ID of the event to find
   * @returns DomainEvent OK
   * @throws ApiError
   */
  public static getEvent(id: string): CancelablePromise<DomainEvent> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/audit-trail/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the audittrail for berth safety events
   * Find all recent domain events about berth safety for a specific berth.
   *
   * @param berthId the ID of the berth to find the events for
   * @param pageNumber The page number to use. 1-based, defaults to 1.
   * @param pageSize The page size to use. 10, 25, 50, 100,....
   * @returns BerthSafetyAuditTrailResponse OK
   * @throws ApiError
   */
  public static getBerthSafetyAuditTrail(berthId: string, pageNumber?: number, pageSize?: number): CancelablePromise<BerthSafetyAuditTrailResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/audit-trail/berth-safety/{berthId}',
      path: {
        berthId: berthId,
      },
      query: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
}
