import { ThemeOptions, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { deepmerge } from '@mui/utils';

declare module '@mui/material/styles' {
  interface Palette {
    below_lower_limit: Palette['primary'];
    above_upper_limit: Palette['primary'];
    above_upper_customer_limit: Palette['primary'];
    above_lower_limit: Palette['primary'];
    disabled: Palette['primary'];
  }

  interface PaletteOptions {
    below_lower_limit: PaletteOptions['primary'];
    above_upper_limit: PaletteOptions['primary'];
    above_upper_customer_limit: PaletteOptions['primary'];
    above_lower_limit: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];
  }
}
export let smartMooringTheme = createTheme({
  palette: {
    background: {
      default: '#fefefe',
    },
    primary: {
      light: '#e3f2ff',
      main: '#0D74DC',
      dark: '#003566',
    },
    secondary: {
      main: '#e3f2ff',
      dark: '#badefe',
    },
    error: {
      main: '#9f0000',
    },
    warning: {
      main: '#e99c00',
    },
    text: {
      primary: '#2a3448',
    },
    disabled: {
      main: '#64748B',
      contrastText: '#fff',
    },
    below_lower_limit: {
      main: '#0d74dc',
      contrastText: '#fff',
    },
    above_lower_limit: {
      main: '#e99c00',
      contrastText: '#fff',
    },
    above_upper_customer_limit: {
      main: '#ff1a1a',
      contrastText: '#fff',
    },
    above_upper_limit: {
      main: '#9f0000',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Barlow', 'Segoe UI', 'Roboto', 'Droid Sans', 'sans-serif', '-apple-system', 'BlinkMacSystemFont'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.8rem',
      },
      '@media (min-width:900px)': {
        fontSize: '2rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.2rem',
      },
      '@media (max-width:599px)': {
        fontSize: '1.5rem',
      },
      fontWeight: 'bolder',
    },
    h2: {
      fontSize: '1.6rem',
      '@media (min-width:600px)': {
        fontSize: '1.4rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.6rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.8rem',
      },
      fontWeight: 'bolder',
    },
    h3: {
      fontSize: '1.3rem',
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.4rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.5rem',
      },
      fontWeight: 'bolder',
    },
    h4: {
      fontSize: '1.15rem',
      '@media (min-width:600px)': {
        fontSize: '1.2rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.3rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.4rem',
      },
      fontWeight: 'bolder',
    },
    h5: { fontSize: '1.1rem', fontWeight: 'bolder' },
    h6: { fontSize: '1.0rem', fontWeight: 'bolder' },
    body1: { fontSize: '1rem' },
    body2: { fontSize: '1.1rem' },
    subtitle1: { fontSize: '1.5rem', fontWeight: 'bolder' },
    subtitle2: { fontSize: '1.2rem', fontWeight: 'bolder' },
    header: {
      fontSize: '12px',
      '@media (min-width:600px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.1rem',
      },
      fontWeight: '600',
      color: '#2a3448',
      verticalAlign: 'text-bottom',
    },
    headerLarge: {
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '28px',
      verticalAlign: 'text-bottom',
    },
    highlightBoldLarge: {
      fontWeight: '700',
      color: '#0d74dc',
      fontSize: '20px',
      lineHeight: '26px',
    },
    highlightBold: {
      fontWeight: '700',
      color: '#0d74dc',
      fontSize: '16px',
      lineHeight: '19px',
    },
    highlightSemiBoldLarge: {
      fontWeight: '600',
      color: '#0d74dc',
      fontSize: '20px',
      lineHeight: '26px',
    },
    highlightSemiBold: {
      fontWeight: '600',
      color: '#0d74dc',
      fontSize: '16px',
      lineHeight: '19px',
    },
    highlightSemiBoldMedium: {
      fontWeight: '600',
      color: '#0d74dc',
      fontSize: '14px',
      lineHeight: '16px',
    },
    highlightSemiBoldSmall: {
      fontWeight: '600',
      color: '#0d74dc',
      fontSize: '12px',
      lineHeight: '16px',
    },
    highlightRegular: {
      fontWeight: '400',
      color: '#0d74dc',
      fontSize: '16px',
      lineHeight: '19px',
    },
    highlightRegularMedium: {
      fontWeight: '400',
      color: '#0d74dc',
      fontSize: '14px',
      lineHeight: '20px',
    },
    highlightRegularSmall: {
      fontWeight: '400',
      color: '#0d74dc',
      fontSize: '12px',
      lineHeight: '16px',
    },
    bodySemiBoldMedium: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '16px',
    },
    bodySemiBoldSmall: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '14px',
    },
    bodyRegularSmall: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
    },
    bodyRegularMedium: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#2a3448',
    },
    bodyRegularLarge: {
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '26px',
      color: '#2a3448',
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 32,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: '#0d74dc',
          '& .MuiListItemText-root': {
            fontSize: '20px',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          maxWidth: 32,
          fontSize: 32,
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#0d74dc',
            '&:hover': {
              color: '#000',
              backgroundColor: '#0d74dc',
            },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          color: 'white',
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#e3f2ff',
          color: '#2a3448',
          border: '1px solid #5eb5fe',
          lineHeight: 1,
          fontSize: 18,
          '& .MuiTooltip-arrow': {
            '&::before': {
              backgroundColor: '#5eb5fe',
            },
          },
          padding: 8,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 3,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': { backgroundColor: '#e3f2ff' },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          textTransform: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 54,
          maxHeight: 54,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#fefefe',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          backgroundColor: '#fefefe',
          color: '#000000',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: 30,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '14px',
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '14px',
          color: '#0d74dc',
          backgroundColor: '#fff',
          textTransform: 'none',
          padding: '5px 20px',
          border: '1px solid #0d74dc',
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#0d74dc',
            '&:hover': {
              color: '#000',
              backgroundColor: '#0d74dc',
            },
          },
        },
      },
    },
  },
  navLinkActive: {
    color: '#0d74dc',
    backgroundColor: '#e3f2ff',
  },
});

smartMooringTheme = responsiveFontSizes(smartMooringTheme);

export const lightTheme: ThemeOptions = deepmerge(smartMooringTheme, {
  palette: {
    mode: 'light',
  },
});

export const darkTheme: ThemeOptions = deepmerge(smartMooringTheme, {
  palette: {
    mode: 'dark',
    text: {
      primary: '#fefefe',
    },
    background: {
      default: '#999999',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    secondary: {
      main: '#1A2027',
      dark: '#131415',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontSize: '24px',
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#ffffff',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
  },
  navLinkActive: {
    color: '#0d74dc',
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
});

declare module '@mui/material/styles' {
  interface Theme {
    navLinkActive: {
      color: string;
      backgroundColor: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    navLinkActive: {
      color: string;
      backgroundColor: string;
    };
  }

  interface TypographyVariants {
    header: TypographyStyleOptions;
    headerLarge: React.CSSProperties;

    highlightBold: React.CSSProperties;
    highlightBoldLarge: React.CSSProperties;

    highlightSemiBold: React.CSSProperties;
    highlightSemiBoldLarge?: React.CSSProperties;
    highlightSemiBoldMedium: React.CSSProperties;
    highlightSemiBoldSmall: React.CSSProperties;

    highlightRegular: React.CSSProperties;
    highlightRegularSmall: React.CSSProperties;
    highlightRegularMedium: React.CSSProperties;

    bodySemiBoldSmall: React.CSSProperties;
    bodySemiBoldMedium: React.CSSProperties;
    bodyRegularSmall: React.CSSProperties;
    bodyRegularMedium: React.CSSProperties;
    bodyRegularLarge: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    header?: TypographyStyleOptions;
    headerLarge?: React.CSSProperties;

    highlightBold?: React.CSSProperties;
    highlightBoldLarge?: React.CSSProperties;

    highlightSemiBold?: React.CSSProperties;
    highlightSemiBoldLarge?: React.CSSProperties;
    highlightSemiBoldMedium?: React.CSSProperties;
    highlightSemiBoldSmall?: React.CSSProperties;

    highlightRegular?: React.CSSProperties;
    highlightRegularMedium?: React.CSSProperties;
    highlightRegularSmall?: React.CSSProperties;

    bodySemiBoldSmall?: React.CSSProperties;
    bodySemiBoldMedium?: React.CSSProperties;
    bodyRegularSmall?: React.CSSProperties;
    bodyRegularMedium?: React.CSSProperties;
    bodyRegularLarge: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    header: true;
    headerLarge: true;

    highlightBold: true;
    highlightBoldLarge: true;

    highlightSemiBold: true;
    highlightSemiBoldLarge: true;
    highlightSemiBoldMedium: true;
    highlightSemiBoldSmall: true;

    highlightRegular: true;
    highlightRegularSmall: true;
    highlightRegularMedium: true;

    bodySemiBoldSmall: true;
    bodySemiBoldMedium: true;

    bodyRegularSmall: true;
    bodyRegularMedium: true;
    bodyRegularLarge: true;
  }
}
