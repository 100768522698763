/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Berth } from '../models/Berth';
import type { MooringLineCharacteristicsInterpolationParameters } from '../models/MooringLineCharacteristicsInterpolationParameters';
import type { MooringLineCharacteristicsRanges } from '../models/MooringLineCharacteristicsRanges';
import type { VesselClearanceAssessment } from '../models/VesselClearanceAssessment';
import type { VesselClearanceAssessmentParameters } from '../models/VesselClearanceAssessmentParameters';
import type { VesselClearanceHistoricalWindProbabilities } from '../models/VesselClearanceHistoricalWindProbabilities';
import type { VesselClearanceSupportedVesselType } from '../models/VesselClearanceSupportedVesselType';
import type { VesselSearchRequest } from '../models/VesselSearchRequest';
import type { VesselTypeCharacteristicsParameterRanges } from '../models/VesselTypeCharacteristicsParameterRanges';
import type { VesselTypeCharacteristicsParameters } from '../models/VesselTypeCharacteristicsParameters';
import type { VesselTypeInterpolationParameters } from '../models/VesselTypeInterpolationParameters';
import type { VesselVesselTypeParameters } from '../models/VesselVesselTypeParameters';

export class VesselClearanceService {
  /**
   * Calculate assessment
   * Calculate vessel clearance assessment based upon the mooring line
   * configuration, bollard capacity at the berth and vessel characteristics.
   *
   * @param xSelectedOrganisationId
   * @param requestBody
   * @returns VesselClearanceAssessment OK
   * @throws ApiError
   */
  public static calculateVesselClearanceAssessment(
    xSelectedOrganisationId: string,
    requestBody: VesselClearanceAssessmentParameters
  ): CancelablePromise<VesselClearanceAssessment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/vessel-clearance/assessment',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get vessel type parameters
   * Retrieve default parameters for a vessel type used in the vessel clearance calculation
   * @param vesselType
   * @returns VesselTypeCharacteristicsParameterRanges OK
   * @throws ApiError
   */
  public static vesselTypeCharacteristicsByVesselType(
    vesselType: VesselClearanceSupportedVesselType
  ): CancelablePromise<VesselTypeCharacteristicsParameterRanges> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vessel-clearance/parameters/vesseltype-characteristics/{vesselType}',
      path: {
        vesselType: vesselType,
      },
    });
  }

  /**
   * Interpolate vessel type physical parameters
   * When changing a parameter of the vessel type characteristics, interpolate what the effect is on the other parameters since they are linked
   * @param vesselType
   * @returns VesselTypeCharacteristicsParameterRanges OK
   * @throws ApiError
   */
  public static interpolateVesselTypeCharacteristics(
    vesselType: VesselClearanceSupportedVesselType
  ): CancelablePromise<VesselTypeCharacteristicsParameterRanges> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/vessel-clearance/parameters/interpolate/vesseltype-characteristics/{vesselType}',
      path: {
        vesselType: vesselType,
      },
    });
  }

  /**
   * Retrieve default parameters for an existing vessel
   * Use the IMO number to find the vessel characteristics to display the parameters for the vessel clearance calculation
   * @param imoNumber
   * @returns VesselTypeCharacteristicsParameters OK
   * @throws ApiError
   */
  public static vesselCharacteristicsByImoNumber(imoNumber: string): CancelablePromise<VesselTypeCharacteristicsParameters> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vessel-clearance/parameters/vessel-characteristics/{imoNumber}',
      path: {
        imoNumber: imoNumber,
      },
    });
  }

  /**
   * Interpolate vessel type parameters
   * When adjusting one of the vessels parameters, interpolate the other parameters
   * @param vesselType
   * @param requestBody
   * @returns VesselTypeCharacteristicsParameters OK
   * @throws ApiError
   */
  public static interpolateVesselTypeParameters(
    vesselType: VesselClearanceSupportedVesselType,
    requestBody: VesselTypeInterpolationParameters
  ): CancelablePromise<VesselTypeCharacteristicsParameters> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/vessel-clearance/parameters/interpolate/vesselType/{vesselType}',
      path: {
        vesselType: vesselType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Interpolate mooring line characteristics
   * Interpolate the number of breast lines and minimum breaking load using the vessel type and its vessel characteristics
   * @param requestBody
   * @returns MooringLineCharacteristicsRanges OK
   * @throws ApiError
   */
  public static mooringLineCharacteristics(
    requestBody: MooringLineCharacteristicsInterpolationParameters
  ): CancelablePromise<MooringLineCharacteristicsRanges> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/vessel-clearance/parameters/mooring-line-characteristics',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve wind probabilities
   * Retrieve wind probabilities based on historical wind information.
   * The probabilities are based on the wind information for the last 10 years.
   *
   * @param xSelectedOrganisationId
   * @param berthId
   * @param lineWllSurpassedForBeaufort
   * @param lineMblSurpassedForBeaufort
   * @param bollardThresholdSurpassedForBeaufort
   * @param bollardSwlSurpassedForBeaufort
   * @returns VesselClearanceHistoricalWindProbabilities OK
   * @throws ApiError
   */
  public static getHistoricalWindProbabilities(
    xSelectedOrganisationId: string,
    berthId: string,
    lineWllSurpassedForBeaufort: number,
    lineMblSurpassedForBeaufort: number,
    bollardThresholdSurpassedForBeaufort: number,
    bollardSwlSurpassedForBeaufort: number
  ): CancelablePromise<VesselClearanceHistoricalWindProbabilities> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vessel-clearance/historical-wind-probabilities',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
      query: {
        berthId: berthId,
        lineWllSurpassedForBeaufort: lineWllSurpassedForBeaufort,
        lineMblSurpassedForBeaufort: lineMblSurpassedForBeaufort,
        bollardThresholdSurpassedForBeaufort: bollardThresholdSurpassedForBeaufort,
        bollardSwlSurpassedForBeaufort: bollardSwlSurpassedForBeaufort,
      },
    });
  }

  /**
   * Retrieve list of berths
   * Retrieve a list of berths for the selected organisation.
   * When a user has access to multiple organisations the organisation passed in the header is used to retrieve the berths for.
   *
   * @param xSelectedOrganisationId
   * @returns Berth OK
   * @throws ApiError
   */
  public static listBerths(xSelectedOrganisationId: string): CancelablePromise<Array<Berth>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vessel-clearance/berths',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
    });
  }

  /**
   * Find a vessel by IMO number.
   * Search for a vessel using the IMO number
   * @param requestBody
   * @returns VesselVesselTypeParameters OK
   * @throws ApiError
   */
  public static searchVessel(requestBody: VesselSearchRequest): CancelablePromise<VesselVesselTypeParameters> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/vessel-clearance/vessel-search',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
      },
    });
  }
}
