import { useState } from 'react';
import Feedback from '@mui/icons-material/Feedback';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
// import QuestionMark from '@mui/icons-material/QuestionMark';
import { Box, Drawer as MuiDrawer, Theme, Toolbar, styled } from '@mui/material';
import { CSSObject } from '@mui/system';
import AppMenuGroup from '@/components/common/Menu/AppMenuGroup';
import { MenuItemGroupProps } from '@/components/common/Menu/types';
import FeedbackComponent from '@/components/feedback/Feedback/FeedbackComponent';

interface SideBarProperties {
  menuGroups: MenuItemGroupProps[];
  isOpen: boolean;
  hideSupportMenuGroup?: boolean;
}

const drawerWidth = '20%';
const drawerMinWidth = 290;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  minWidth: drawerMinWidth,
  maxHeight: '100vh',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(5)} )`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} )`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function SideBarMenu({ menuGroups, isOpen, hideSupportMenuGroup }: SideBarProperties) {
  const [dialogVisible, setDialogVisible] = useState(false);

  function openFeedbackDialog() {
    setDialogVisible(true);
  }

  if (menuGroups == null) return <></>;

  const defaultSupportMenuGroup: MenuItemGroupProps = {
    title: 'Support',
    showTitle: true,
    showDividerAfter: false,
    // icon: <QuestionMark />,
    items: [
      {
        displayText: 'Knowledge base',
        icon: <HelpCenterIcon />,
        externalLinkTo: 'https://support.royalhaskoningdhv.com/knowledgebase/?textsearch=Smart%20Mooring',
      },
      {
        displayText: 'Feedback',
        icon: <Feedback />,
        onClick: () => openFeedbackDialog(),
      },
    ],
  };

  return (
    <Box sx={{ display: 'flex', flexGrow: 0 }}>
      <Drawer
        variant="permanent"
        open={isOpen}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Toolbar variant={'dense'} sx={{ minHeight: 54, maxHeight: 54 }} />
        {menuGroups.map((group) => (
          <AppMenuGroup {...{ ...group, collapsed: !isOpen }} key={group.title} />
        ))}

        {!hideSupportMenuGroup && (
          <Box sx={{ position: 'absolute', bottom: 10, width: '100%' }}>
            {<AppMenuGroup {...{ ...defaultSupportMenuGroup, collapsed: !isOpen }} key={'supportMenu'} />}
            <FeedbackComponent isOpen={dialogVisible} setOpen={setDialogVisible} />
          </Box>
        )}
      </Drawer>
    </Box>
  );
}

export default SideBarMenu;
