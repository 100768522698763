import { Typography } from '@mui/material';
import SmartMooringLogo from '@/assets/images/smartmooring-logo.png';
import css from './index.module.css';

function AppLogo() {
  return (
    <Typography variant="body2" noWrap component="div">
      <img src={SmartMooringLogo} className={css.logoImage} alt={'Smart Mooring logo'} title={'Smart Mooring logo'} />
    </Typography>
  );
}

export default AppLogo;
