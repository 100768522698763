import { ComponentType, FC } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { NAV_MISSING_FEATURE, NAV_NO_LICENSE, NAV_PERMISSION_DENIED, isErrorPage } from '@/features/Authentication/Auth0ProviderSmartMooring';
import useLicence from '@/hooks/useLicence';

const ProtectedRoute = <P extends object>({
  component,
  licenceFeature,
  allowedRoles,
}: {
  component: ComponentType<P>;
  licenceFeature?: string;
  allowedRoles?: string[];
}) => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const { active, hasFeature, hasRole } = useLicence();

  const path = location.pathname + location.search;

  if (!isAuthenticated) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if (!isErrorPage(path)) localStorage.setItem('redirectUrl', path);
    return <Navigate to="/" state={{ returnTo: path }} />;
  }

  if (!active && path !== '/no-license') {
    localStorage.removeItem('redirectUrl');
    return <Navigate to={NAV_NO_LICENSE} />;
  }

  if (licenceFeature && !hasFeature(licenceFeature)) {
    localStorage.removeItem('redirectUrl');
    return <Navigate to={NAV_MISSING_FEATURE} state={{ returnTo: path, licenceFeature }} />;
  }

  if (!hasRole(allowedRoles)) {
    localStorage.removeItem('redirectUrl');
    return <Navigate to={NAV_PERMISSION_DENIED} state={{ returnTo: path, licenceFeature, allowedRoles: allowedRoles }} />;
  }

  const AuthComponent = withAuthenticationRequired(component) as FC<unknown>;
  return <AuthComponent />;
};

export default ProtectedRoute;
