import mixpanel from 'mixpanel-browser';
import { appSettings } from '@/services/config';

const analytics_configured = appSettings.analytics.token != null;

let analytics_enabled = false;

function isTrackingDisabled() {
  return !analytics_configured;
}

const actions = {
  initialize: () => {
    analytics_enabled = !isTrackingDisabled();
    if (analytics_enabled) {
      mixpanel.init(appSettings.analytics.token, {
        autotrack: true,
        // debug: true,
        ignore_dnt: true,
        disable_cookie: true,
        persistence_name: 'smart-mooring',
        persistence: 'localStorage',
        cross_subdomain_cookie: false,
      });
      mixpanel.register({ environment: process.env.ENV });
      console.log('Mixpanel initialized');
    } else {
      analytics_enabled = false;
    }
  },
  identify: (id: string) => {
    if (analytics_enabled) mixpanel.identify(id);
  },
  setUserProfile: (userId: string, organisationName: string) => {
    if (analytics_enabled && mixpanel) {
      mixpanel.identify(userId);
      mixpanel.set_group('organisations', organisationName);
      mixpanel.people.set({ organisation: organisationName });
      mixpanel.register({ organisationName: organisationName });
    }
  },
  organisation: (organisationId: string) => {
    if (analytics_enabled) mixpanel.register({ organisation: organisationId });
  },
  register: (props: object) => {
    if (analytics_enabled) mixpanel.register(props);
  },
  alias: (id: string) => {
    if (analytics_enabled) mixpanel.alias(id);
  },
  pageview: (title: string) => {
    if (analytics_enabled) mixpanel.track('pageView', { title: title });
  },
  track: (name: string, props: object) => {
    if (analytics_enabled) mixpanel.track(name, props);
  },
  people: {
    set: (props: object) => {
      if (analytics_enabled) mixpanel.people.set(props);
    },
    append: (propertyName: string, value: string) => {
      if (analytics_enabled) mixpanel.people.append(propertyName, value);
    },
  },
  disable: () => {
    mixpanel.disable();
    analytics_enabled = false;
  },
};

export const Analytics = actions;
