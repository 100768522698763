import { ElementType, createElement } from 'react';
import { ButtonProps } from '@mui/material';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventProps } from '@/types';

function ButtonWrapper<TComponent extends ElementType>({
  component,
  children,
  onClick,
  analyticsEventName,
  analyticsEventProperties,
  ...otherProps
}: ButtonProps<TComponent, { component: TComponent } & AnalyticsEventProps>) {
  function onClickWithAnalytics(event: React.MouseEvent<HTMLButtonElement>) {
    onClick && onClick(event);

    analyticsEventProperties && Analytics.track(analyticsEventName, analyticsEventProperties);
  }

  return createElement(
    component,
    {
      onClick: onClickWithAnalytics,
      ...otherProps,
    },
    children
  );
}

export default ButtonWrapper;
