import { MouseEvent, ReactElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';

interface GridOverviewTooltipIconProps {
  title: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  icon: ReactElement;
  disabled?: boolean;
}

const variantBackgroundColor = {
  filled: 'primary.light',
};
const variantHoverBackgroundColor = {
  filled: 'secondary.dark',
};

const variantColor = {
  filled: 'black',
};

function GridOverviewTooltipIcon({ title, onClick, icon, disabled = false }: GridOverviewTooltipIconProps) {
  const variant = 'filled';
  const iconButton = (
    <IconButton
      color="primary"
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: variantBackgroundColor[variant],
        color: variantColor[variant],
        '&:hover': { backgroundColor: variantHoverBackgroundColor[variant] },
      }}
    >
      {icon}
    </IconButton>
  );

  // If disabled, return simple icon. Tooltips shouldn't have disabled buttons (from Mui warnings)
  if (disabled) {
    return <>{iconButton}</>;
  }

  return (
    <Tooltip sx={{ paddingY: 0 }} title={title} arrow={true}>
      {iconButton}
    </Tooltip>
  );
}

export default GridOverviewTooltipIcon;
