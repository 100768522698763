import { useCallback, useEffect, useState } from 'react';
import { ContentCopy, Key } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import usePageTitle from '@/hooks/usePageTitle';
import { appSettings } from '@/services/config';

export default function Token() {
  usePageTitle('Access Token');

  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = useCallback(() => {
    if (accessToken != null) {
      navigator.clipboard.writeText(accessToken);
      enqueueSnackbar('Copied to clipboard', {
        preventDuplicate: true,
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 2000,
      });
    }
  }, [enqueueSnackbar, accessToken]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: `profile email ${appSettings.authentication.scopes}`,
          },
        });
        setAccessToken(accessToken);
      } catch (e) {
        console.log(e);
      }
    };
    getUserMetadata()
      .then((r) => r)
      .catch((e) => console.log(e));
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <Box sx={{ padding: '10px', display: 'flex', alignItems: 'flex-end', '& > :not(style)': { m: 1 } }} maxWidth={'lg'}>
      <TextField
        sx={{ color: '#000000' }}
        fullWidth={true}
        type={'text'}
        label={'Access token'}
        variant={'standard'}
        focused
        value={accessToken ? accessToken : ''}
        size={'medium'}
        color={'primary'}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Key />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="copy access token" onClick={handleClick} edge="end" disabled={!accessToken}>
                <ContentCopy />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth={true}
        value={moment(user?.updated_at).format('DD-MM-YYYY HH:mm:ss')}
        size={'medium'}
        variant={'standard'}
        color={'primary'}
        type={'text'}
        InputProps={{
          readOnly: true,
        }}
        label={'Last login'}
      />
      <TextField
        fullWidth={true}
        value={user?.email}
        size={'medium'}
        variant={'standard'}
        color={'primary'}
        type={'text'}
        InputProps={{
          readOnly: true,
        }}
        label={'Email address'}
      />
    </Box>
  );
}
