import { TableCell } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';
import { GridOverviewColumnMetadata } from './types';

interface GridOverviewTableBodyCellProps<TRowModel> {
  cell: Cell<TRowModel, unknown>;
  metadata: GridOverviewColumnMetadata;
}

function GridOverviewTableBodyCell<TRowModel>({ cell, metadata }: GridOverviewTableBodyCellProps<TRowModel>) {
  return <TableCell align={metadata.align}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>;
}

export default GridOverviewTableBodyCell;
