/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { DmaCaseDefinitions } from '../models/DmaCaseDefinitions';
import type { OrganisationSummary } from '../models/OrganisationSummary';
import type { User } from '../models/User';
import type { UserPreferences } from '../models/UserPreferences';

export class ProfileService {
  /**
   * Get user profile
   * Retrieve the profile settings for the logged in user, using the Smart Society personId
   * @returns User OK
   * @throws ApiError
   */
  public static getUser(): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/profile/user',
    });
  }

  /**
   * Update user preferences
   * Update the user preferences for the logged in user, using the Smart Society personId
   * @param requestBody
   * @returns UserPreferences OK
   * @throws ApiError
   */
  public static updateUserPreferences(requestBody: UserPreferences): CancelablePromise<UserPreferences> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/profile/user/preferences',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get organisation information
   * Retrieve the organisation information for the logged in user, using the Smart Society organisationId, so we can see which dma cases are activated for this organisation
   * @param xSelectedOrganisationId
   * @returns OrganisationSummary OK
   * @throws ApiError
   */
  public static getOrganisation(xSelectedOrganisationId: string): CancelablePromise<OrganisationSummary> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/profile/organisation',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
    });
  }

  /**
   * Get all DMA cases
   * Retrieve the dma cases purchased or in evaluation by the current organisation
   * @param xSelectedOrganisationId
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static getDmaCases(xSelectedOrganisationId: string): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/profile/dma-cases',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
    });
  }

  /**
   * Get purchased DMA cases
   * Retrieve the dma cases that have been purchased by the current organisation
   * @param xSelectedOrganisationId
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static getPurchasedDmaCases(xSelectedOrganisationId: string): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/profile/dma-cases/purchased',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
    });
  }

  /**
   * Get evaluation DMA cases
   * Retrieve the dma cases configured for the current organisation to be evaluated
   * @param xSelectedOrganisationId
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static getEvaluationDmaCases(xSelectedOrganisationId: string): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/profile/dma-cases/evaluation',
      headers: {
        'X-Selected-Organisation-Id': xSelectedOrganisationId,
      },
    });
  }
}
