import { TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import { Table } from '@tanstack/react-table';
import { GridOverviewPaginationState } from './types';

const borderStyle = '1px solid rgba(224, 224, 224, 1)';

const StyledTablePagination = styled('div')({
  border: borderStyle,
});

interface GridOverviewTablePaginationProps<TRowModel> {
  table: Table<TRowModel>;
  pagination: GridOverviewPaginationState;
}
function GridOverviewTablePagination<TRowModel>({ table, pagination }: GridOverviewTablePaginationProps<TRowModel>) {
  return (
    <TablePagination
      rowsPerPageOptions={[25, 50, 100, 500, 1000]}
      component={StyledTablePagination}
      count={pagination?.rowCount ?? table.getFilteredRowModel().rows?.length}
      rowsPerPage={pagination.pageSize}
      page={pagination.pageIndex}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      onPageChange={(_, page) => {
        table.setPageIndex(page);
      }}
      onRowsPerPageChange={(e) => {
        const size = e.target.value ? Number(e.target.value) : 100;
        table.setPageSize(size);
      }}
    />
  );
}

export default GridOverviewTablePagination;
