import EmailIcon from '@mui/icons-material/Email';
import HelpIcon from '@mui/icons-material/HelpOutline';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage } from 'usehooks-ts';
import loginImage from '@/assets/images/smartmooring-login.png';
import SmartMooringLogo from '@/assets/images/smartmooring-logo.png';
import TwinnLogo from '@/assets/images/twinn-logo.png';
import usePageTitle from '@/hooks/usePageTitle';
import DisclaimerDialog from '@/parts/Disclaimer/DisclaimerDialog';
import css from './index.module.css';

function LoginPage() {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const [hasAcceptedDisclaimer, setHasAcceptedDisclaimer] = useLocalStorage<boolean>('accepted', false);
  usePageTitle('Login - Smart Mooring');

  function onAccept() {
    setHasAcceptedDisclaimer(true);
  }

  const closeDisclaimer = () => {
    return;
  };

  return (
    <Grid
      container
      component={'div'}
      direction={'row'}
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      // spacing={{ xs: 2, md: 3 }}
    >
      <Grid item xs={false} sm={5} md={7} xl={8} sx={{ height: '100vh', objectFit: 'cover' }} display={{ xs: 'none', sm: 'block' }}>
        <img src={loginImage} alt="Smart Mooring, moored ship" style={{ objectFit: 'cover', height: '100%', width: '95%' }} />
      </Grid>
      <Grid item sm={7} md={5} xl={4} sx={{ height: '100vh' }}>
        {!hasAcceptedDisclaimer && <DisclaimerDialog accepted={hasAcceptedDisclaimer} onAccept={onAccept} onClose={closeDisclaimer} />}
        <Grid container alignItems={'stretch'} justifyContent={'space-between'} direction={'column'} columnGap={1} sx={{ height: '99vh' }}>
          <Grid item alignContent={'center'} sx={{ top: 40, position: 'relative', objectFit: 'fill', height: '100', width: '95%' }}>
            <img src={SmartMooringLogo} alt={'Smart Mooring logo'} loading={'lazy'} style={{ objectFit: 'contain', height: '60%' }} />
          </Grid>
          <Grid item>
            <Box className={css.loginHeader}>
              <Typography variant={'h1'} pb={8} height={100} flexWrap={'wrap'}>
                Smart Mooring Solution
              </Typography>
              <Typography variant={'h2'} pb={3} flexWrap={'wrap'}>
                Create safer, smarter and more efficient ports
              </Typography>
              <Button
                sx={{ minWidth: 200, maxWidth: 300, zIndex: 1000 }}
                size={'large'}
                variant="contained"
                color={'primary'}
                disabled={isLoading || isAuthenticated}
                onClick={() => loginWithRedirect()}
              >
                Log in with Smart Society
              </Button>
            </Box>
            <Box pt={2}>
              <HelpIcon sx={{ fontSize: 32, verticalAlign: 'bottom' }} className={css.linkIcon} />
              <Link href="https://support.smart-society.io/support/home">Need help with logging in?</Link>
            </Box>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Grid container alignItems="stretch" justifyContent="space-between" direction={'row'}>
              <Grid sx={{ display: 'flex', alignItems: 'flex-end', zIndex: '100', objectFit: 'contain' }} item xs={12} sm={4} md={3}>
                <img src={TwinnLogo} width={120} alt={'Twinn logo'} loading={'lazy'} />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Typography variant={'h3'}>More info?</Typography>
                <Box flexDirection={'row'} display={'flex'} bottom={5}>
                  <Stack direction={'column'}>
                    <Typography variant={'subtitle2'}>Product details</Typography>
                    <Box>
                      <LinkIcon sx={{ fontSize: 24, verticalAlign: 'text-bottom' }} className={css.linkIcon} />
                      <Link href="https://www.twinn.io/en/services/smart-mooring">https://www.twinn.io/en/services/smart-mooring</Link>
                    </Box>

                    <Typography variant={'subtitle2'}>Contact us</Typography>
                    <Box>
                      <EmailIcon sx={{ fontSize: 24, verticalAlign: 'text-bottom' }} className={css.linkIcon} />
                      <Link href="mailto:smart.mooring.support@royalhaskoningdhv.com">smart.mooring.support@royalhaskoningdhv.com</Link>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoginPage;
