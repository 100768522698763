import { Box, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import NotFoundImage from '@/assets/images/notfound.png';

type FeatureMissingProps = {
  featureName: string;
  message?: string;
};

function MissingFeature() {
  const location = useLocation();
  const state = location.state as { licenceFeature?: string };

  return (
    <>
      <MissingFeatureMessage featureName={state?.licenceFeature} />
    </>
  );
}

export function MissingFeatureMessage({ featureName, message }: FeatureMissingProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <Grid container direction={'row'}>
        <Grid item md={7} pl={5}>
          <h1>{featureName} not enabled</h1>
          {!message && (
            <Typography variant={'bodyRegularMedium'}>
              This feature `{featureName}` is not activated with your current licence.
              <br />
              Please contact our team so we can help you enable this feature. You can contact us by sending an email to&nbsp;
              <a href={'mailto:smart.mooring.support@royalhaskoningdhv.com'}>smart.mooring.support@royalhaskoningdhv.com</a>
            </Typography>
          )}
          {message && <Typography variant={'bodyRegularMedium'}>{message}</Typography>}
        </Grid>
        <Grid item md={5}>
          <img src={NotFoundImage} alt={'Feature not found'} width={400} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MissingFeature;
