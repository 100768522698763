/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { DmaCaseDefinitions } from '../models/DmaCaseDefinitions';
import type { VesselTypeClassDefinitions } from '../models/VesselTypeClassDefinitions';

export class DmaLibraryService {
  /**
   * Get library DMA cases
   * Retrieve all DMA cases in the DMA library (so only the GENERIC ones), for showing them for sales purposes.
   * @returns DmaCaseDefinitions OK
   * @throws ApiError
   */
  public static getLibraryDmaCases(): CancelablePromise<Array<DmaCaseDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dma-library/dma-cases',
    });
  }

  /**
   * Get vessel classes
   * Retrieve the vessel classes for the logged in user, using the Smart Society organisationId, so we can see which dma cases are activated for this organisation
   * @returns VesselTypeClassDefinitions OK
   * @throws ApiError
   */
  public static getVesselClasses(): CancelablePromise<Array<VesselTypeClassDefinitions>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dma-library/vessel-classes',
    });
  }
}
