/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Vessel types, the main categories vessels are categorized in within Smart Mooring, linking to DMA cases.
 */
export enum VesselType {
  TANKER = 'Tanker',
  GENERAL_CARGO = 'General cargo',
  BULK_CARRIER = 'Bulk carrier',
  CONTAINER = 'Container',
  CRUISE = 'Cruise',
  ROPAX = 'Ropax',
  RORO = 'Roro',
  LNG_CARRIER = 'LNG carrier',
  LPG_CARRIER = 'LPG carrier',
  FSRU = 'FSRU',
  TUG = 'Tug',
  MISCELLANEOUS = 'Miscellaneous',
}
