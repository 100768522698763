import { ExitToApp } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import FactCheckSharpIcon from '@mui/icons-material/FactCheckSharp';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { MenuItemGroupProps } from '@/components/common/Menu/types';
import { ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER, ROLE_SYSTEM_ADMINISTRATOR } from '@/parts/UserMenu/UserMenu';

export const adminMenuGroups: MenuItemGroupProps[] = [
  {
    title: 'Safety prediction',
    showTitle: true,
    showDividerAfter: true,
    allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
    items: [
      {
        displayText: 'Current safety overview',
        description: 'Safety overview displaying a list of potential unsafe berths, moored vessels etc.',
        icon: <SafetyCheckIcon />,
        linkTo: '/admin/safety-warnings',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
      {
        displayText: 'Moored vessels',
        description: 'Currently moored vessels',
        icon: <DirectionsBoat />,
        linkTo: '/admin/moored-vessels',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
    ],
  },
  {
    title: 'Audit trail',
    showTitle: true,
    showDividerAfter: true,
    allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
    items: [
      {
        displayText: 'Audit trail',
        description: 'List of events that have occurred in the system. To see what is processed by the system.',
        icon: <ChecklistIcon />,
        linkTo: '/admin/audit-trail',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
    ],
  },
  {
    title: 'Environment configuration',
    showTitle: true,
    showDividerAfter: true,
    allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
    items: [
      {
        displayText: 'Organisations',
        icon: <AccountTreeIcon />,
        linkTo: '/admin/organisations',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
      {
        displayText: 'People',
        icon: <GroupIcon />,
        linkTo: '/admin/people',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
      {
        displayText: 'Berths',
        icon: <LocationOnIcon />,
        linkTo: '/admin/berths',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
      {
        displayText: 'Metocean stations',
        icon: <WbSunnyIcon />,
        linkTo: '/admin/metocean-stations',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
    ],
  },
  {
    title: 'DMA case management',
    showTitle: true,
    showDividerAfter: false,
    allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_DMA_ENGINEER, ROLE_CONFIGURATION_ADMINISTRATOR],
    items: [
      {
        displayText: 'DMA matrices',
        icon: <AppsSharpIcon width={24} />,
        linkTo: '/admin/dma',
        disabled: false,
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_DMA_ENGINEER, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
      {
        displayText: 'DMA matrix row finder',
        icon: <FactCheckSharpIcon />,
        linkTo: '/admin/matrix-row-finder',
        allowedRoles: [ROLE_SYSTEM_ADMINISTRATOR, ROLE_DMA_ENGINEER, ROLE_CONFIGURATION_ADMINISTRATOR],
      },
    ],
  },
  {
    title: 'Return to application',
    showTitle: true,
    showDividerAfter: false,
    items: [{ displayText: 'Back to safety prediction', icon: <ExitToApp />, linkTo: '/safety-prediction' }],
  },
];
