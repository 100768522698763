import { FilterFn, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useBerthManagementServiceGetBerthSafetyWarnings } from '@/api/management/queries';
import { BerthDmaCaseSafetyWarning } from '@/api/management/requests';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import GridOverview from '@/components/management/GridOverview/GridOverview';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from '@/components/management/GridOverview/types';
import usePageTitle from '@/hooks/usePageTitle';

const columnHelper = createColumnHelper<BerthDmaCaseSafetyWarning>();

const getTimestampValue = (value: string) => {
  const timestamp = moment(value);
  return timestamp.local().format('DD MMMM HH:mm');
};

const getColumns = () => [
  columnHelper.accessor('berthId', {
    header: 'Identifier',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('dmaCaseId', {
    header: 'DMA',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('timestamp', {
    header: 'Timestamp',
    cell: (info) => getTimestampValue(info.getValue()),
    filterFn: ((row, columnId, value: string) => {
      const timeStamp = getTimestampValue(row.getValue(columnId) ?? '');

      return !!timeStamp.toLocaleLowerCase().match(value.toLocaleLowerCase());
    }) as FilterFn<BerthDmaCaseSafetyWarning>,
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('percentage', {
    header: 'Percentage',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('loadingCondition', {
    header: 'Loading condition',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('vesselDisposition', {
    header: 'Vessel disposition',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
];

function BerthSafetyOverview() {
  const { data, error } = useBerthManagementServiceGetBerthSafetyWarnings({ pageNumber: 1, pageSize: 100 });

  const pageTitle = 'Berth safety warnings';
  usePageTitle(pageTitle);

  if (error) {
    return <ApiErrorAlert error={error} />;
  }
  if (data) {
    return <GridOverview columns={getColumns()} title={pageTitle} data={data.berthDmaCaseSafetyWarnings} getRowId={(row) => `${row.id}`} />;
  }
  return <LoadingIndicator message={'Searching for berth safety warnings'} />;
}

export default BerthSafetyOverview;
