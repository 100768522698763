import { useAuth0 } from '@auth0/auth0-react';
import { localStorageOrganisationIdKey } from '@/features/Common/SmartMooringProvider';

function Logout() {
  const { isAuthenticated, logout } = useAuth0();
  if (isAuthenticated) {
    localStorage.removeItem(localStorageOrganisationIdKey);
    localStorage.removeItem('redirectUrl');
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  return <></>;
}

export default Logout;
