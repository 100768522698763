import { useCallback, useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authNamespace } from '@/features/Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '@/features/Authentication/Auth0Types';
import SmartMooringContext from '@/features/Common/SmartMooringContext';
import { appSettings } from '@/services/config';
import { LicenseFeatures } from '@/types';

function useLicence() {
  const { selectedOrganisation } = useContext(SmartMooringContext);
  const { user } = useAuth0();
  const userProfile: CustomClaims = user?.[authNamespace];
  const defaultOrganisationId = userProfile?.organization_id;
  const defaultLicenseAgreements = userProfile?.license_agreements;
  const features = useMemo(() => selectedOrganisation?.features ?? [], [selectedOrganisation?.features]);
  const allowedFeatures = useMemo(
    () =>
      Object.entries(LicenseFeatures)
        .flatMap(([key, value]) => [key, value.valueOf()])
        .filter((f) => features.includes(f)),
    [features]
  );

  const licenseAgreements = useMemo(
    () => defaultLicenseAgreements?.filter((license) => license.organization_id == defaultOrganisationId) ?? [],
    [defaultOrganisationId, defaultLicenseAgreements]
  );
  const products = useMemo(
    () => licenseAgreements?.flatMap((license) => license.products)?.filter((p) => p.name == appSettings.license.productName) ?? [],
    [licenseAgreements]
  );

  const licenseExpiryDate = useMemo(() => {
    const expiryDates = licenseAgreements?.map((license) => license.end_date) ?? [];
    return expiryDates.every(Boolean) ? Math.max(...expiryDates) : undefined;
  }, [licenseAgreements]);

  const roles = useMemo(() => products?.flatMap((product) => product.roles?.map((r) => r.name)) ?? [], [products]);
  const hasFeature = useCallback((feature?: string) => !feature || allowedFeatures.includes(feature), [allowedFeatures]);
  const hasRole = useCallback(
    (requestedRoles?: string[]) => !requestedRoles || (requestedRoles && requestedRoles.some((role) => roles.includes(role))),
    [roles]
  );

  return {
    active: products.length > 0,
    roles,
    features,
    hasFeature,
    hasRole,
    licenseExpiryDate,
  };
}

export default useLicence;
