import AddModeratorIcon from '@mui/icons-material/AddModerator';
import GppBadIcon from '@mui/icons-material/GppBad';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { FilterFn, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment/moment';
import { useAuditTrailServiceGetBerthSafetyAuditTrail } from '@/api/management/queries';
import { BerthSafetyEventSummary } from '@/api/management/requests';
import { MetoceanForecast } from '@/api/ui/requests';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import GridOverview from '@/components/management/GridOverview/GridOverview';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from '@/components/management/GridOverview/types';
import usePageTitle from '@/hooks/usePageTitle';

const columnHelper = createColumnHelper<BerthSafetyEventSummary>();

const getForecastTimestampValue = (value: string) => {
  return moment(value).local().format('DD MMMM HH:mm');
};

const getPredictionTimeValue = (value: string) => {
  const timestamp = moment(value);
  if (timestamp.isBefore(moment().startOf('D'))) return timestamp.local().format('DD MMMM HH:mm:ss.SSS');
  else return timestamp.local().format('HH:mm:ss.SSS');
};

const getLastUpdateValue = (value: string) => {
  return moment(value).local().format('DD MMMM HH:mm:ssZ');
};

const getColumns = () => [
  columnHelper.accessor('eventType', {
    header: '+/-',
    cell: (info) => {
      if (info.getValue() == 'BerthDmaCaseSafetyWarningsEncountered') return <AddModeratorIcon color={'warning'} />;
      else return <GppBadIcon color={'error'} />;
    },
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 0.2, showFilterIcon: false } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('forecast', {
    header: 'Forecast timestamp',
    cell: (info) => getForecastTimestampValue(info.getValue()?.timestamp),
    filterFn: ((row, columnId, value: string) => {
      const metoceanForecast = row.getValue(columnId) as MetoceanForecast;
      const timestamp = getForecastTimestampValue(metoceanForecast.timestamp);

      return !!timestamp.toLocaleLowerCase().match(value.toLocaleLowerCase());
    }) as FilterFn<BerthSafetyEventSummary>,
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('dmaCaseId', {
    header: 'DMA case',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('loadingCondition', {
    header: 'Loading condition',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('vesselDisposition', {
    header: 'Disposition',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('percentage', {
    header: 'Percentage',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('simulationId', {
    header: 'Simulation ID',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('timestamp', {
    header: 'Prediction time',
    cell: (info) => getPredictionTimeValue(info.getValue()),
    filterFn: ((row, columnId, value: string) => {
      const predictionTime = getPredictionTimeValue(row.getValue(columnId));
      return !!predictionTime.match(value);
    }) as FilterFn<BerthSafetyEventSummary>,
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('sequenceNumber', {
    header: 'SequenceNr',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 0.5 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('lastUpdate', {
    header: 'Last update',
    cell: (info) => getLastUpdateValue(info.getValue()),
    filterFn: ((row, columnId, value: string) => {
      const lastUpdate = getLastUpdateValue(row.getValue(columnId));
      return !!lastUpdate.toLocaleLowerCase().match(value.toLocaleLowerCase());
    }) as FilterFn<BerthSafetyEventSummary>,
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
];

function BerthSafetyAuditTrail() {
  const { berthId } = useParams();

  // default to 'pageSize: 1000'
  const { data, error, isError } = useAuditTrailServiceGetBerthSafetyAuditTrail({ berthId: berthId, pageSize: 1000 });

  const pageTitle = `Safety prediction audit trail: ${berthId}`;
  usePageTitle(pageTitle);

  if (isError) {
    return <ApiErrorAlert error={error} />;
  }

  function backButton() {
    return (
      <Button variant="contained" component={Link} to={'/admin/berths'} startIcon={<NavigateBeforeIcon />}>
        Back to berths
      </Button>
    );
  }

  if (data) {
    return (
      <>
        {backButton()}
        <GridOverview<BerthSafetyEventSummary>
          columns={getColumns()}
          getRowId={(row) => row.forecast.timestamp + '::' + row.eventIdentifier + row.dmaCaseId + row.loadingCondition + row.vesselDisposition}
          title={pageTitle}
          data={data.events}
          createUri=""
        />
      </>
    );
  }

  return <LoadingIndicator message={'Loading berth safety prediction audit trail'} />;
}

export default BerthSafetyAuditTrail;
