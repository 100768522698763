import { ReactElement, useContext, useEffect } from 'react';
import { useDocumentTitle } from 'usehooks-ts';
import SmartMooringContext from '@/features/Common/SmartMooringContext';
import { Analytics } from '@/services/analytics';

function usePageTitle(title: string, titleIcon?: ReactElement | undefined) {
  useDocumentTitle(title);

  const { pageTitle, pageTitleIcon, setPageTitle, setPageTitleIcon } = useContext(SmartMooringContext);

  useEffect(() => {
    if (title == pageTitle) return;

    setPageTitle(title);
    Analytics.pageview(title);
  }, [setPageTitle, pageTitle, title]);

  useEffect(() => {
    if (titleIcon && !titleIcon.key) {
      throw new SyntaxError("Missing key for prop: 'titleIcon'.");
    }

    if (titleIcon?.key !== pageTitleIcon?.key) {
      setPageTitleIcon(titleIcon);
    }
  }, [setPageTitleIcon, pageTitleIcon?.key, titleIcon]);
}

export default usePageTitle;
