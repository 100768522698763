import { createColumnHelper } from '@tanstack/react-table';
import { useVesselManagementServiceGetMooredVessels } from '@/api/management/queries';
import { MooredVessel } from '@/api/management/requests';
import GridOverview from '@/components/management/GridOverview/GridOverview';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from '@/components/management/GridOverview/types';
import usePageTitle from '@/hooks/usePageTitle';

const columnHelper = createColumnHelper<MooredVessel>();

const getColumns = () => [
  columnHelper.accessor('berthId', {
    header: 'Identifier',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('berthName', {
    header: 'Name',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('vesselMmsi', {
    header: 'MMSI',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('vesselName', {
    header: 'Vessel name',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('vesselType', {
    header: 'Type',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('vesselClass', {
    header: 'Class',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('arrival', {
    header: 'Moored at',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
];

function MooredVesselOverview() {
  const { data } = useVesselManagementServiceGetMooredVessels();

  const pageTitle = 'Moored vessels';
  usePageTitle(pageTitle);

  return <GridOverview columns={getColumns()} title={pageTitle} data={data} getRowId={(row) => row.visitId} />;
}

export default MooredVesselOverview;
