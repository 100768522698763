import { Box } from '@mui/material';
import { styled } from '@mui/system';

const OutletBox = styled(Box)(({ theme }) => ({
  marginLeft: `calc(${theme.spacing(7)})`,
  [theme.breakpoints.up('sm')]: {
    marginLeft: `calc(${theme.spacing(8)})`,
  },
  marginTop: 54,
  overflow: 'hidden',
}));

export default OutletBox;
