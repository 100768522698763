import { Grid } from '@mui/material';
import { styled } from '@mui/system';

const MainContent = styled(Grid)(({ theme }) => ({
  minHeight: `calc(100vh - ${theme.spacing(7)} - ${theme.spacing(4)} )`,
  marginTop: `calc(${theme.spacing(7)})`,
  width: `calc(100vw - ${theme.spacing(5)} )`,
  marginLeft: `calc(${theme.spacing(5)})`,
  [theme.breakpoints.up('sm')]: {
    marginLeft: `calc(${theme.spacing(6)})`,
    width: `calc(100vw - ${theme.spacing(6)} )`,
  },
  overflow: 'hidden',
  zIndex: 100,
}));
export default MainContent;
