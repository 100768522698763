import { MouseEvent, useState } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { FilterFn, createColumnHelper } from '@tanstack/react-table';
import { useAuditTrailServiceFindEvents } from '@/api/management/queries';
import { EventSummary } from '@/api/management/requests';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import { getTimestampValue } from '@/components/management/GridOverview/GridOverview';
import GridOverviewTooltipIcon from '@/components/management/GridOverview/GridOverviewTooltipIcon';
import PagedGridOverview from '@/components/management/GridOverview/PagedGridOverview';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from '@/components/management/GridOverview/types';
import EventDialog from '@/features/Admin/AuditTrail/AuditTrailEvent';
import usePageTitle from '@/hooks/usePageTitle';

const columnHelper = createColumnHelper<EventSummary>();

const typeColumns = [
  columnHelper.accessor('aggregateType', {
    header: 'Type',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('aggregateIdentifier', {
    header: 'Identifier',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
];

const getColumns = (showAllColumns: boolean = false, viewAuditTrailEvent: (eventIdentifier: string) => void) => [
  ...(showAllColumns ? typeColumns : []),
  ...detailColumns(viewAuditTrailEvent),
];

const detailColumns = (viewAuditTrailEvent: (eventIdentifier: string) => void) => [
  columnHelper.accessor('timestamp', {
    header: 'Timestamp',
    cell: (info) => getTimestampValue(info.getValue()),
    filterFn: ((row, columnId, value: string) => {
      const timestamp = getTimestampValue(row.getValue(columnId) ?? '');
      return !!timestamp.match(value);
    }) as FilterFn<EventSummary>,
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('description', {
    header: 'Event',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('user', {
    header: 'UserId',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Actions',
    enableSorting: false,
    meta: { align: 'center', flex: 1.5, showFilterIcon: false } as GridOverviewColumnMetadata,
    cell: (props) => {
      const eventIdentifier = props.row.original.eventIdentifier;

      const onClickViewEvent = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // don't select this row after clicking
        viewAuditTrailEvent(eventIdentifier);
      };
      return (
        <>
          <GridOverviewTooltipIcon title="View event contents" onClick={onClickViewEvent} icon={<VisibilityIcon />} />
        </>
      );
    },
  }),
];

function AuditTrail() {
  const [searchParams] = useSearchParams();
  const aggregateType = searchParams.get('type') as string;
  const identifier = searchParams.get('identifier') as string;

  const pageTitle = 'Audit trail' + (aggregateType ? `: ${aggregateType} ${identifier}` : '');
  usePageTitle(pageTitle);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const { data, error, isError } = useAuditTrailServiceFindEvents({
    type: aggregateType,
    id: identifier,
    pageNumber: pageNumber,
    pageSize: pageSize,
  });

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    if (value !== undefined) setSelectedEvent(value);
  };

  const showEvent = (eventIdentifier: string) => {
    if (eventIdentifier) setSelectedEvent(eventIdentifier);
    handleClickOpen();
  };

  if (isError) {
    return <ApiErrorAlert error={error} />;
  }

  function backButton() {
    if (aggregateType === 'Organisation') {
      return (
        <Button variant="contained" component={Link} to={'/admin/organisations/'} startIcon={<NavigateBeforeIcon />}>
          Back to Organisations
        </Button>
      );
    } else if (aggregateType === 'Berth') {
      return (
        <Button variant="contained" component={Link} to={'/admin/berths'} startIcon={<NavigateBeforeIcon />}>
          Back to berths
        </Button>
      );
    } else if (aggregateType === 'Person') {
      return (
        <Button variant="contained" component={Link} to={'/admin/people'} startIcon={<NavigateBeforeIcon />}>
          Back to people overview
        </Button>
      );
    } else if (aggregateType === 'DmaCaseMatrix') {
      return (
        <Button variant="contained" component={Link} to={'/admin/dma'} startIcon={<NavigateBeforeIcon />}>
          Back to DMA cases
        </Button>
      );
    } else if (aggregateType === 'MetoceanStation') {
      return (
        <Button variant="contained" component={Link} to={'/admin/metocean-stations'} startIcon={<NavigateBeforeIcon />}>
          Back to Metocean stations
        </Button>
      );
    }
  }

  if (data) {
    return (
      <>
        {backButton()}
        {selectedEvent && <EventDialog eventIdentifier={selectedEvent} onClose={handleClose} open={open} />}
        <PagedGridOverview
          columns={getColumns(aggregateType == null, (eventIdentifier) => {
            showEvent(eventIdentifier);
          })}
          title={pageTitle}
          data={data.events}
          getRowId={(row, index) => row.aggregateIdentifier + row.sequenceNumber + index}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={data.pageCount ?? 1}
          rowCount={data.eventCount ?? -1}
        />
      </>
    );
  }

  return <LoadingIndicator message={'Loading audit trail'} />;
}

export default AuditTrail;
