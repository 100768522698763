import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import moment from 'moment-timezone';
import { MatrixRowSelection } from '@/features/Admin/MatrixRowFinder/MatrixRowFinder';

function MatrixRowSelectionView({ loading, errorMessage, result }: MatrixRowSelection) {
  if (loading) {
    return <CircularProgress />;
  }

  if (errorMessage != null) {
    return (
      <Box>
        <h3>Error</h3>
        <Grid container>
          <Grid item xs={12}>
            <p>{errorMessage}</p>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (result == null) {
    return null;
  }
  return (
    <Grid container xs={12} columnSpacing={4}>
      <Grid item xs={3}>
        <>
          <h3>Forecast</h3>
          Your timezone = {moment.tz.guess(true)}
          <dl>
            <dt>Timestamp</dt>
            <dd>{moment(result.forecast.timestamp).format('DD-MM-YYYY HH:mm')}</dd>

            <dt>Swell height</dt>
            <dd>{result.forecast.swell?.height ?? '-'} m</dd>

            <dt>Swell direction</dt>
            <dd>{result.forecast.swell?.direction ?? '-'} deg</dd>

            <dt>Swell peak period</dt>
            <dd>{result.forecast.swell?.peakPeriod ?? '-'} s</dd>

            <dt>Wave height</dt>
            <dd>{result.forecast.wave?.height ?? '-'} m</dd>

            <dt>Wave direction</dt>
            <dd>{result.forecast.wave?.direction ?? '-'} deg</dd>

            <dt>Wave peak period</dt>
            <dd>{result.forecast.wave?.peakPeriod ?? '-'} s</dd>

            <dt>Total sea wave height</dt>
            <dd>{result.forecast.totalSeaWave?.height ?? '-'} m</dd>

            <dt>Total sea wave direction</dt>
            <dd>{result.forecast.totalSeaWave?.direction ?? '-'} deg</dd>

            <dt>Total sea wave peak period</dt>
            <dd>{result.forecast.totalSeaWave?.peakPeriod ?? '-'} s</dd>

            <dt>Wind direction</dt>
            <dd>{result.forecast.wind.direction} deg</dd>

            <dt>Wind speed</dt>
            <dd>{result.forecast.wind.speed * 1.9438444924406} knots</dd>
          </dl>
        </>
      </Grid>

      <Grid item xs={3}>
        <h3>Input</h3>

        <dl>
          <dt>Berth orientation</dt>
          <dd>{result.berthOrientation} deg</dd>

          <dt>Priority</dt>
          <dd>{humanise(result.priority)}</dd>
        </dl>
      </Grid>

      <Grid item xs={6}>
        <h3>Row</h3>

        <dl>
          <dt>Simulation ID</dt>
          <dd>{result.simulationId}</dd>

          <dt>Max line force</dt>
          <dd>{result.maxLineForce}</dd>

          <dt>Max fender deflection</dt>
          <dd>{result.maxFenderDeflection ?? '-'}</dd>

          <dt>Max bollard force</dt>
          <dd>{result.maxBollardForce ?? '-'}</dd>

          <dt>Max surge</dt>
          <dd>{result.maxSurge}</dd>

          <dt>Max sway</dt>
          <dd>{result.maxSway}</dd>

          <dt>Max heave</dt>
          <dd>{result.maxHeave}</dd>

          <dt>Max yaw</dt>
          <dd>{result.maxYaw}</dd>

          <dt>Max pitch</dt>
          <dd>{result.maxPitch}</dd>

          <dt>Max roll</dt>
          <dd>{result.maxRoll}</dd>
        </dl>
      </Grid>
    </Grid>
  );
}

export function humanise(text: string) {
  return text.charAt(0) + text.substring(1).replace('_', ' ').toLowerCase();
}

export default MatrixRowSelectionView;
