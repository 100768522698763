/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { MatrixRowSelectionRequest } from '../models/MatrixRowSelectionRequest';
import type { MatrixRowSelectionResponse } from '../models/MatrixRowSelectionResponse';

export class BehindTheScenesService {
  /**
   * @param requestBody
   * @returns MatrixRowSelectionResponse OK
   * @throws ApiError
   */
  public static selectMatrixRow(requestBody: MatrixRowSelectionRequest): CancelablePromise<MatrixRowSelectionResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/bts/dma-case-matrix-row/result',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `No row found`,
      },
    });
  }
}
