import { MouseEvent, useCallback, useState } from 'react';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import {
  useBerthManagementServiceDisableMooringDetectionForBerth,
  useBerthManagementServiceEnableMooringDetection,
  useBerthManagementServiceListBerths,
  useBerthManagementServiceRegisterBerth,
} from '@/api/management/queries';
import { Berth, BerthRequest, BerthResponse, MooringDetectionResponse } from '@/api/management/requests';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import GridOverviewTooltipIcon from '@/components/management/GridOverview/GridOverviewTooltipIcon';
import PagedGridOverview from '@/components/management/GridOverview/PagedGridOverview';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from '@/components/management/GridOverview/types';
import usePageTitle from '@/hooks/usePageTitle';
import EditBerthDialog from './EditBerthDialog';
import ToggleMooringDetectionForBerthDialog from './ToggleMooringDetectionForBerthDialog';

const columnHelper = createColumnHelper<Berth>();

const getColumns = (
  navigate: NavigateFunction,
  openToggleMooringDetectionDialog: (berth: Berth) => void,
  openEditModalDialog: (berth: Berth) => void
) => [
  columnHelper.accessor('berthId', {
    header: 'Identifier',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('orientation', {
    header: 'Orientation',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 0.5 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('bollardInformation.height', {
    header: 'Bollard height',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('bollardInformation.capacity', {
    header: 'Bollard capacity',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('mooringDetectionEnabled', {
    header: 'AIS',
    cell: (props) => {
      const berth = props.row.original;

      const onToggleMooringDetection = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // don't select this row after clicking

        openToggleMooringDetectionDialog(berth);
      };

      return (
        <GridOverviewTooltipIcon
          title={`Toggle Mooring Detection`}
          onClick={onToggleMooringDetection}
          icon={berth.mooringDetectionEnabled ? <SensorsIcon color={'success'} /> : <SensorsOffIcon color={'error'} />}
        />
      );
    },
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 0.5 } as GridOverviewColumnMetadata,
    filterFn: 'includesString',
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Actions',
    enableSorting: false,
    meta: { align: 'center', flex: 1, showFilterIcon: false } as GridOverviewColumnMetadata,
    cell: (props) => {
      const berth = props.row.original;

      const onViewAuditTrail = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // don't select this row after clicking

        navigate(`/admin/audit-trail?type=Berth&identifier=${berth.berthId}`);
      };

      const onViewBerthSafetyAuditTrail = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // don't select this row after clicking

        navigate(`/admin/audit-trail/berths/${berth.berthId}`);
      };

      const onModifyBerth = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // don't select this row after clicking

        openEditModalDialog(berth);
      };

      return (
        <>
          <GridOverviewTooltipIcon title="Modify Berth" onClick={onModifyBerth} icon={<ModeEditIcon />} />
          <GridOverviewTooltipIcon title="Audit Trail" onClick={onViewAuditTrail} icon={<ChecklistIcon />} />
          <GridOverviewTooltipIcon title="Berth Safety Audit Trail" onClick={onViewBerthSafetyAuditTrail} icon={<FactCheckIcon />} />
        </>
      );
    },
  }),
];

function BerthOverview() {
  const navigate = useNavigate();
  const [toggleMooringDetectionBerth, setToggleMooringDetectionBerth] = useState<Berth | undefined>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const { data, refetch: refetchBerths } = useBerthManagementServiceListBerths({ pageNumber: pageNumber, pageSize: pageSize });
  const enableMutation = useBerthManagementServiceEnableMooringDetection();
  const disableMutation = useBerthManagementServiceDisableMooringDetectionForBerth();
  const updateBerthMutation = useBerthManagementServiceRegisterBerth();
  const [editModalBerth, setEditModalBerth] = useState<Berth | undefined>();

  const pageTitle = 'Berths';
  usePageTitle(pageTitle);

  const getMutationValues = () => {
    if (toggleMooringDetectionBerth?.mooringDetectionEnabled) {
      return {
        berthId: toggleMooringDetectionBerth?.berthId,
      };
    }

    return {
      requestBody: [toggleMooringDetectionBerth?.berthId],
    };
  };

  const getModifyValues = (berth: Berth) => {
    return {
      requestBody: {
        ...berth,
        location: {
          latitude: berth.location.latitude,
          longitude: berth.location.longitude,
        },
        bollardInformation: {
          capacity: berth.bollardInformation.capacity,
          height: berth.bollardInformation.height,
        },
      },
    };
  };

  const closeToggleMooringDetectionDialog = useCallback(
    async (toggled: boolean) => {
      if (toggled) {
        await refetchBerths();
      }
      setToggleMooringDetectionBerth(undefined);
    },
    [refetchBerths]
  );

  const closeEditBerthDialog = useCallback(
    async (modified: boolean) => {
      if (modified) {
        await refetchBerths();
      }
      setEditModalBerth(undefined);
    },
    [refetchBerths]
  );

  if (data) {
    return (
      <>
        <ToggleMooringDetectionForBerthDialog<
          MooringDetectionResponse[] | MooringDetectionResponse,
          unknown,
          { requestBody: string[] } | { berthId: string },
          unknown
        >
          open={!!toggleMooringDetectionBerth}
          berth={toggleMooringDetectionBerth}
          mutation={toggleMooringDetectionBerth?.mooringDetectionEnabled ? disableMutation : enableMutation}
          getMutationValues={getMutationValues}
          closeDialog={closeToggleMooringDetectionDialog}
        />
        <EditBerthDialog<BerthResponse, unknown, { requestBody: BerthRequest } | { berthId: string }, unknown>
          open={!!editModalBerth}
          berth={editModalBerth}
          mutation={updateBerthMutation}
          getMutationValues={getModifyValues}
          closeDialog={closeEditBerthDialog}
        />
        <PagedGridOverview
          columns={getColumns(
            navigate,
            (berth: Berth) => setToggleMooringDetectionBerth(berth),
            (berth: Berth) => setEditModalBerth(berth)
          )}
          data={data.berths}
          title={pageTitle}
          getRowId={(row) => row.berthId}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={data.pageCount ?? 1}
          rowCount={data.berthCount ?? -1}
        />
      </>
    );
  }

  return <LoadingIndicator message={'Loading berths...'} />;
}

export default BerthOverview;
