import { useAuth0 } from '@auth0/auth0-react';
import { OpenAPI as ManagementOpenAPI } from '@/api/management/requests';
import { OpenAPI as UIOpenAPI } from '@/api/ui/requests';
import { appSettings } from '@/services/config';
import { authNamespace } from '../Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '../Authentication/Auth0Types';

const ApiSmartMooring = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  if (user) {
    const resolveAccessToken = () =>
      new Promise<string>((resolve) => {
        const token = getAccessTokenSilently({
          authorizationParams: { scope: `profile email ${appSettings.authentication.scopes}` },
        });
        return resolve(token);
      });

    const userProfile: CustomClaims = user?.[authNamespace];
    if (userProfile?.organization_id) {
      const headers = {
        'x-smart-society-organisation-id': userProfile?.organization_id,
      };

      UIOpenAPI.HEADERS = headers;
      ManagementOpenAPI.HEADERS = headers;
    }

    UIOpenAPI.BASE = appSettings.api.uiEndpoint;
    UIOpenAPI.TOKEN = resolveAccessToken;

    ManagementOpenAPI.BASE = appSettings.api.managementEndpoint;
    ManagementOpenAPI.TOKEN = resolveAccessToken;
  }

  return <></>;
};

export default ApiSmartMooring;
