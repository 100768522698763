import { ColumnDef, PaginationState, Row } from '@tanstack/react-table';

export interface GridOverviewProps<TRowModel> {
  data?: TRowModel[];
  columns: ColumnDef<TRowModel>[];
  title: string;
  createUri?: string;
  getRowId: (originalRow: TRowModel, index: number, parent?: Row<TRowModel>) => string;
  gridOverviewPaginationState?: GridOverviewPaginationState;
  setGridOverviewPaginationState?: (pagination: PaginationState) => void;
}

export interface PagedGridOverviewProps<TRowModel> {
  data?: TRowModel[];
  columns: ColumnDef<TRowModel>[];
  title: string;
  createUri?: string;
  getRowId: (originalRow: TRowModel, index: number, parent?: Row<TRowModel>) => string;
  pageNumber: number;
  setPageNumber: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
  pageCount: number;
  rowCount: number;
}

export interface GridOverviewColumnMetadata {
  align: 'center' | 'left' | 'right' | 'inherit' | 'justify';
  showFilterIcon: boolean;
  flex?: number;
}

export const DefaultGridOverviewColumnMetadata = {
  align: 'left',
  showFilterIcon: true,
  flex: 1,
} as GridOverviewColumnMetadata;

export interface GridOverviewPaginationState extends PaginationState {
  pageCount?: number;
  rowCount?: number;
}
