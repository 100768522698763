import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import OutletBox from '@/components/layout/OutletBox/OutletBox';
import Footer from '@/parts/Footer/Footer';
import { adminMenuGroups } from '@/parts/Sidebar/AdminSideBarMenu';
import TopNavigationBar from '@/parts/TopNavigationBar/TopNavigationBar';

function AdminLayout() {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 10px)', // todo: fix footer so it has the right height so we do not need the -10px.
      }}
    >
      <Grid item xs={12}>
        <TopNavigationBar menuGroups={adminMenuGroups} hideSupportMenuGroup={true} />
      </Grid>
      <Grid item xs={12} p={2} style={{ flexGrow: 1 }}>
        <OutletBox>
          <Outlet />
        </OutletBox>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default AdminLayout;
