import { DividerProps, Divider as MuiDivider, styled } from '@mui/material';

const StyledDivider = styled(MuiDivider)(({ theme }) => ({
  borderColor: theme.palette.secondary.dark,
  marginLeft: '-1px',
}));

function Divider(props: DividerProps) {
  return <StyledDivider orientation={props.orientation || 'vertical'} flexItem {...props} />;
}

export default Divider;
