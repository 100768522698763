import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LatLng, Map } from 'leaflet';

interface MapState {
  zoom?: number;
  lat?: number;
  lng?: number;
}

function useMapParams(disableSearchParams?: boolean) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mapState, setMapState] = useState<MapState>({});

  const zoom = disableSearchParams ? mapState.zoom : searchParams.get('zoom');
  const lat = disableSearchParams ? mapState.lat : searchParams.get('lat');
  const lng = disableSearchParams ? mapState.lng : searchParams.get('lng');

  const center: LatLng | undefined = lat && lng ? new LatLng(+lat, +lng) : undefined;
  const zoomLevel = zoom ? +zoom : undefined;

  const setMapParams = (map: Map) => {
    const mapCenter = map.getCenter();

    if (disableSearchParams) {
      setMapState({
        zoom: map.getZoom(),
        lat: mapCenter.lat,
        lng: mapCenter.lng,
      });
    } else {
      const params = Object.fromEntries([...searchParams, ['zoom', map.getZoom()], ['lat', mapCenter.lat], ['lng', mapCenter.lng]]);
      setSearchParams(params, { replace: !!zoom });
    }
  };

  return {
    zoomLevel,
    center,
    setMapParams,
  };
}

export default useMapParams;
